import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from '../configuracion/auth.config';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {

  constructor(private oAuthService: OAuthService, private router: Router) {
    this.configureWithNewConfigApi();
  }

  private configureWithNewConfigApi() {
    this.oAuthService.configure(authConfig);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.loadDiscoveryDocumentAndTryLogin();
  }

  get identityClaims(): object {
    return this.oAuthService.getIdentityClaims();
  }

  get accessToken() {
    return this.oAuthService.getAccessToken();
  }

  login(): void {
    this.oAuthService.initImplicitFlow();
  }

  logOut(): void {
    sessionStorage.clear();
    localStorage.clear();
    // this.cookieService.deleteAll();
    this.oAuthService.logOut();
  }

  hasValidAccessToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

}
