<app-navbar></app-navbar>
  <div id="content">
    <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <div id="container">
      <div class="contenedor container mt-5">
        <form name="orden">
          <div class="row">
            <div class="col-6">
              <label>Code:</label>
              <input type="text" class="form-control col-9" name="code" [(ngModel)]="jicCode"/>
              <small class="text-danger" *ngIf="errorCode">Código no Existe en Sistema</small>
            </div>
            <div class="col-6">
              <label >Flota: </label>
              <select name="flotaNumber" id="" class="form-control col-md-6" [(ngModel)]="fleet" (change)="checkCode()">
                <option value="A320">A320</option>
                <option value="B767">B767</option>
                <option value="B777">B777</option>
                <option value="B787">B787</option>
                <option value="A350">A350</option>
              </select>
            </div>
            <div class="col-6">
              <label >Assembly: </label>
              <select name="assemblyNumber" id="" class="form-control col-md-7" [(ngModel)]="assembly" (change)="checkCode()">
                <option value="A320-FAM">A320-FAM</option>
                <option value="A350-900">A350-900</option>
                <option value="APS5000">APS5000</option>
                <option value="B767-300">B767-300</option>
                <option value="B777-FAM">B777-FAM</option>
                <option value="B787">B787</option>
                <option value="CF6-80C2">CF6-80C2</option>
                <option value="CFM56-5B">CFM56-5B</option>
                <option value="FACH-767">FACH-767</option>
                <option value="GE90-100">GE90-100</option>
                <option value="HGT1700">HGT1700</option>
                <option value="PW1100G">PW1100G</option>  
                <option value="T-1000">T-1000</option>
                <option value="TRENTXWB">TRENTXWB</option>
                <option value="V2500">V2500</option> 
              </select>
            </div>            
          </div>
          <h2> Referencia
              <!--<i class="material-icons float-right add" (click)="addReference()"> add </i>-->
            </h2>
            <div>
              <div class="d-flex">
                <div class="form-group col-6">
                  <label for="exampleFormControlInput1">Nombre: </label>
                  <input type="text" name="referencia" class="form-control" [(ngModel)]="jobCard.documentReference" id="exampleFormControlInput1" placeholder="Referencia">
                </div>
              </div>
            </div>
          <div class="row">
            <div class="col-12">
              <h2>Información General</h2>
              <ckeditor [config]="ckconfig" name="generalDescription" [editorUrl]="editorUrl" [(ngModel)]="jobCard.instructions" debounce="500"></ckeditor>
            </div>
          </div>
          
          <h2>Instrucciones de Trabajo 
            <i class="material-icons float-right add" (click)="addStep()"> add </i>
          </h2>
          <div class="row" *ngFor="let steps of jobCard.jobCardSteps; let indice=index; ">
            <div class="col-12">
              <div class="col-12">
                <i class="material-icons float-right" (click)="removeStep(indice)" *ngIf="jobCard.jobCardSteps.length > 1"> clear </i>
                <label>Paso {{indice + 1}}</label>
                <ckeditor [config]="ckconfig" name="generalDescription{{indice}}" [editorUrl]="editorUrl" [(ngModel)]="steps.description" debounce="500"></ckeditor>
              </div>
            </div>
            <div class="col-12 mb-5">
                <div class="d-flex">
                  <div class="col-4">
                    <label>Rango Aplicable:</label>
                    <input type="text" class="form-control col-12"  [(ngModel)]="steps.applicabilityRange"  name="applicabilityRange{{indice}}">
                  </div>
                  <div class="d-flex col-8 mt-5">
                    <div class="ml-5">
                      <input type="checkbox" id="{{indice}}laborskill1" name="{{indice}}laborskill1" [(ngModel)]="loquequieras1[indice]" >
                      <label> Se debe indicar quién lo inspeccionó </label>
                    </div>                    
                </div>
              </div>
            </div>
          </div>
        </form>

        <!-- Button trigger modal -->
        <div class="contenedor botones abajo d-flex">
          <button type="button" *ngIf="previewButton" class="btn btn-primary col-2 previsualizar" id="preview" data-toggle="modal" data-target="#exampleModal">Previsualizar</button>
        </div>
        <div class="alert alert-danger" *ngIf="error" role="alert">
          Problemas en el Envío
          <button (click)="error = false;" type="button" id="closeModal" class="close pull-right" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Previzualizacion</h5>
            <button type="button" id="closeModal" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="preview">
              <div class="header">
                <h1>A. REFERENCE</h1>
                <div>
                  <p>{{jobCard.documentReference}}</p>
                </div>
              </div>
              <div class="step">
                  <h1>B. ACCOMPLISHMENT INSTRUCTIONS</h1>
                  <p [innerHTML]="jobCard.instructions"></p>
              </div>
              <div class="step" *ngFor="let intruction of jobCard.jobCardSteps; let indice=index;">
                  <h1 *ngIf="indice == 0">C. WORK INSTRUCTIONS</h1>
                  <p [innerHTML]="intruction.description"></p>                 
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" (click)="submit()" data-dismiss="modal">Enviar</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
