<div class="mt-1 animated fast ">
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <div class="card fondo">
                <form action="" (ngSubmit)="submit(forma)" #forma="ngForm">
                    <div class="card-header">
                        <select [disabled]="!process.profile.selectPrincipal" name="flotaNumber" id=""
                            (change)="stilo();completado2 = false; completado = false; servicio.completado3 = false; rutass = false; mensajes = false; servicio.mensajes = false; contador = 0; versioness = []; dataVersion = []; listaStorage = ''; sentObj.version = ''; sentObj.amm = ''; sentObj.dic = '';"
                            class="form-control w-50" [(ngModel)]="flota" required>
                            <option value="" hidden>{{'seleccione_flota' | translate}}</option>
                            <option value="A320">A320</option>
                            <option value="B767">B767</option>
                            <option value="B777">B777</option>
                            <option value="B787">B787</option>
                            <option value="A350">A350</option>
                        </select>

                    </div>
                    <div class="card-body">
                        <div class="form-group d-flex justify-content-between flex-wrap text-right" *ngIf="flota.length !== 0">
                            <input type="text" [(ngModel)]="sentObj.amm" class="form-control tamano" disabled='disabled'
                                name="sentObj.amm" required placeholder="Rutas de AMM" />
                            <button class="btn botones btn-outline-primary" (click)="
                  mensajes = false;
                  getCarpetas();
                  sentObj.amm = '';
                  rutasAmm = false;
                  sentObj.dic = '';
                  listoConfirmado = false;
                  listoConfirmadoLista = false;
                  servicio.mensajes = false;
                  servicio.completado3 = false;
                  sentObj.version = '';
                  sentObj.pathVersion = '';
                  listaStorage = '';
                  listaStora = false;
                  versioness = [];
                  contador = 0;
                  dataVersion = [];
                  rutass = true; 
                  rutas1 = true;
                  rutas2 = false;
                  rutas3 = false;
                  rutas4 = false;
                  path1 = [];
                  path2 = [];
                  path3 = [];
                  path4 = [];
                  oculto = true;
                  ocultoButom1 = false;
                  ocultoButom2 = false;
                  ocultoButom3 = false;
                  ocultoButom4 = false;
                  ocultoButom5 = false;
                  ocultoButom6 = false;
                  ocultarFlota = false;
                ">
                                <i class="fas fa-folder text-primary"></i>
                            </button>
                        </div>
                        <div class="form-group w-80" *ngIf="rutasAmm">
                            <div *ngIf="loudingImg" class="text-center">
                                <img src="assets/resources/images/spinner.gif"><br>
                                <span class="text-primary font">{{'buscar_versione'| translate}}</span>
                            </div>
                            <select required placeholder="Path Versiones" [(ngModel)]="sentObj.pathVersion"
                                (click)="noEncontradas(); versionSola();" class="form-control mb-3"
                                name="sentObj.pathVersion">
                                <option value="" hidden>{{'path_versiones'| translate}}</option>
                                <option class="text-primary font" *ngFor="let version of versioness" value={{version}}>
                                    {{version}}</option>
                                <option class="text-danger text-center font" *ngIf="noEncontrada" value="">
                                    {{'no_encontraron_versiones' | translate}}</option>

                            </select>

                        </div>


                        <div class="form-group w-80 text-left" *ngIf="rutasAmm">

                            <input type="text" placeholder="{{'version'|translate}}" disabled='disabled'
                                [(ngModel)]="sentObj.version" class="form-control" name="sentObj.version">
                            <small class="form-text text-muted">{{'campo_autogenerado'| translate}}</small>
                        </div>
                        <div class="form-group  d-flex justify-content-between flex-wrap text-right" *ngIf="rutasAmm">

                            <input type="text" [(ngModel)]="sentObj.dic" disabled='disabled' class="form-control tamano"
                                name="sentObj.dic" required placeholder="{{'diccionario'| translate}}" />

                            <button class="btn btn-outline-primary botones" (click)="
                  mensajes= false;
                  getCarpetas();
                  sentObj.dic = '';
                  listoConfirmado = false;
                  servicio.mensajes = false;
                  servicio.completado3 = false;
                  completado3 = false;
                  noEncontrada = false; 
                  rutass = true;
                  rutas1 = false;
                  rutas2 = true;
                  rutas3 = false;
                  rutas4 = false;
                  path1 = [];
                  path2 = [];
                  path3 = [];
                  path4 = [];
                  oculto = false;
                  ocultarFlota = false;
                  ocultoButom1 = true;
                  ocultoButom2 = false;
                  ocultoButom3 = false;
                  ocultoButom4 = false;
                  ocultoButom5 = false;
                  ocultoButom6 = false
                ">
                                <i class="fas fa-folder text-primary"></i>
                            </button>

                        </div>

                  
                        <div class="d-flex justify-content-between flex-wrap">
                            <div *ngIf="process.profile.cargarArchivos" class="form-group  boton procesarPro marginr">
                                <button [disabled]="!listoConfirmado" class="btn btn-primary procesar carga"
                                    type="submit" (click)="
                  
                  
                  
                  submit(forma);
                  mueveReloj();
                  dataVersion = [];
                  rutass = false;
                  rutas1 = false;
                  rutas2 = false;
                  rutas3 = false;
                  rutas4 = false;
                  path1 = [];
                  path2 = [];
                  path3 = [];
                  path4 = [];
                  oculto = false;
                  ocultarFlota = false;
                  ocultoButom1 = false;
                  ocultoButom2 = false;
                  ocultoButom3 = false;
                  ocultoButom4 = false;
                  ocultoButom5 = false;
                  ocultoButom6 = false;
                ">
                                    {{'procesar_imagenes' | translate}}
                                </button>
                            </div>                            
                        </div>
                        <!-- </div> -->
                    </div>

                </form>
            </div>
            <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '6px' }"></ngx-loading> -->
        </div>

        <div class="col-lg-6 col-sm-12">
            <div *ngIf="servicio.mensajes">
                <div class="card fondo">
                    <div class="card-body">
                        <a class="font">{{'fecha' | translate}} :</a> <a class="text-primary">
                            {{servicio.nuevaListaProcesar[0].date | date: 'medium'}}</a>
                        <br>
                        <a class="font">{{'flota' | translate}} :</a> <a> {{servicio.nuevaListaProcesar[0].flota}}</a>
                        <br>
                        <a class="font">Amm :</a> <a> {{servicio.nuevaListaProcesar[0].pathAMM}}</a> <br>
                        <a class="font">{{'diccionario'| translate}} :</a> <a>
                            {{servicio.nuevaListaProcesar[0].pathDiccionario}}</a>
                        <br>
                        <a class="font">{{'ruta' | translate}} :</a> <a>
                            {{servicio.nuevaListaProcesar[0].pathVersion}}</a> <br>
                        <a class="font">{{'version'|translate}} :</a> <a> {{servicio.nuevaListaProcesar[0].version}}</a>
                        <br>
                        <a class="font"> {{'usuario' | translate}} :</a> <a> {{servicio.nuevaListaProcesar[0].user}}</a>
                        <br>
                        <hr>
                        <p class="text-primary font"> {{'proceso_enviado'| translate}}</p>
                    </div>
                </div>
            </div>


            <div *ngIf="servicio.loudingProcesosDerecha" class="text-center">
                <img src="assets/resources/images/spinner.gif"><br>
                <span class="text-primary font">{{'enviando_procesos'| translate}}</span>
            </div>
            <div *ngIf="rutass" class="animated fast">

                <span *ngIf="botonRegresar1">
                    <a (click)="getCarpetas(); mensaje = false; sentObj.amm = ''; sentObj.version = '';
                    sentObj.pathVersion = ''; rutass = true; rutas1 = true; rutas2 = false; path1 = []; path2 = [];path3 = []; oculto = true;
                     ocultoButom1 = false; ocultoButom2 = false; ocultoButom3 = false; ocultarFlota= false"><i
                            class="fas fa-angle-double-down text-primary m-2"></i></a>
                </span>
                <span *ngIf="botonRegresar2">
                    <a
                        (click)="getCarpetas(); mensaje = false; sentObj.dic = '';  sentObj.version = '';
                     sentObj.pathVersion = ''; rutass = true; rutas2 = true; rutas1 =false;path2 = []; path1 = []; path3 = [];
                      ocultoButom1 = true; ocultoButom3 = false; 
                     ocultoButom2= false; oculto = false; ocultarFlota = false">
                        <i class="fas fa-angle-double-down text-primary m-2"></i></a>
                </span>
                <span *ngIf="botonRegresar3">
                    <a
                        (click)="getCarpetas(); mensaje = false; listaStorage = '';
                      rutass = true; rutas2 = false; rutas3 = true; rutas1 = false;path2 = []; path1 = []; path3 = []; ocultoButom3 = true;
                       ocultoButom1 = false;
                      ocultoButom2 = false; ocultoButom4 = false; oculto = false; ocultarFlota = false">
                        <i class="fas fa-angle-double-down text-primary m-2"></i></a>
                </span>
              
              
              
              
              
              
                <span *ngFor="let pat of path1; let i = index">
                    <span *ngIf="pat.endsWith('/')">
                        <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat); sentObj.version = '';
          sentObj.pathVersion = ''; versioness = []; contador = i;" class="hover mouse"><a>{{ pat | splitPipe }}</a>
                            <i class="fas fa-folder ml-1 text-primary mr-2"></i>
                        </a>
                    </span>

                    <span *ngIf="!pat.endsWith('/')">
                        <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse .animated.fast "><a>{{ pat | splitPipe }}</a><i
                                class="far fa-folder-open ml-1 text-primary mr-2"></i>
                        </a>
                    </span>

                </span>


                <span *ngFor="let pat of path2; let i = index">
                    <span *ngIf="pat.endsWith('/')">
                        <a *ngIf="rutas2" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{ pat | splitPipe }}</a><i
                                class="fas fa-folder ml-1 text-primary mr-2"></i></a>
                    </span>
                    <span *ngIf="!pat.endsWith('/')">
                        <a *ngIf="rutas2" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{ pat | splitPipe }}</a><i
                                class="far fa-folder-open ml-1 text-primary mr-2"></i></a>
                    </span>
                </span>

                <span *ngFor="let pat of path3; let i = index">
                    <span *ngIf="pat.endsWith('/')">
                        <a *ngIf="rutas3" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{ pat | splitPipe }}</a><i
                                class="fas fa-folder ml-1 text-primary mr-2"></i></a>
                    </span>
                    <span *ngIf="!pat.endsWith('/')">
                        <a *ngIf="rutas3" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{ pat | splitPipe }}</a><i
                                class="far fa-folder-open ml-1 text-primary mr-2"></i></a>
                    </span>
                </span>

                <div class="card fondo scroll-">
                    <div *ngIf="louding" class="text-center text-primary m-5">
                        <i class=" text-center fas fa-sync fa-spin fa-4x"></i>
                        <p class=" text-center mt-3">{{'espere' | translate}}</p>
                    </div>

                    <div *ngIf="oculto">
                        <div class="card-body mouse" *ngFor="let carpeta of carpetas">
                            <div *ngIf="carpeta.endsWith('/')">
                                <a (click)="mandarRuta(carpeta); oculto = false; ocultarFlota = true"
                                    class="card-title mouse">
                                    <i class="fas fa-folder text-primary mr-2"></i><a>{{ carpeta }}</a>
                                </a>
                            </div>

                            <div *ngIf="!carpeta.endsWith('/')">
                                <a (click)="mandarRuta(carpeta); ocultarFlota = true" class="card-title mouse">
                                    <i class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{ carpeta }}</a>
                                </a>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="ocultarFlota">
                        <div class="card-body mouse" *ngFor="let ruta of Rutas">
                            <div *ngIf="ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta); " class="card-title mouse"><i
                                        class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                            <div *ngIf="!ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse">
                                    <i
                                        class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ocultoButom1">
                        <div class="card-body mouse" *ngFor="let carpeta of segundaCarpeta">
                            <div *ngIf="carpeta.endsWith('/')">
                                <a (click)="
                  mandarRuta(carpeta); ocultoButom1 = false; ocultoButom2 = true
                " class="card-title mouse">
                                    <i class="fas fa-folder m-2 text-primary mr-2"></i>
                                    <a>{{ carpeta }}</a></a>
                            </div>
                            <div *ngIf="!carpeta.endsWith('/')">
                                <a (click)="
                  mandarRuta(carpeta); ocultoButom1 = false; ocultoButom2 = true
                " class="card-title mouse"><i class="fas fa-file-alt ml-1 text-primary mr-2"></i>
                                    <a>{{ carpeta }}</a></a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ocultoButom2">
                        <div class="card-body mouse" *ngFor="let ruta of Rutas">
                            <div *ngIf="ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                            <div *ngIf="!ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="far fa-folder-open m-2 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ocultoButom3">
                        <div class="card-body mouse" *ngFor="let carpeta of terceraCarpeta">
                            <div *ngIf="carpeta.endsWith('/')">
                                <a (click)="
                  mandarRuta(carpeta); ocultoButom3 = false; ocultoButom4 = true
                " class="card-title mouse">
                                    <i class="fas fa-folder m-2 text-primary mr-2"></i>
                                    <a>{{ carpeta }}</a></a>
                            </div>
                            <div *ngIf="!carpeta.endsWith('/')">
                                <a (click)="
                  mandarRuta(carpeta); 
                " class="card-title mouse"><i class="fas fa-file-alt ml-1 text-primary mr-2"></i>
                                    <a>{{ carpeta }}</a></a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ocultoButom4">
                        <div class="card-body mouse" *ngFor="let ruta of Rutas">
                            <div *ngIf="ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                            <div *ngIf="!ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="far fa-folder-open m-2 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ocultoButom5">
                        <div class="card-body mouse" *ngFor="let carpeta of cuartaCarpeta">
                            <div *ngIf="carpeta.endsWith('/')">
                                <a (click)="
                  mandarRuta(carpeta); ocultoButom5 = false; ocultoButom6 = true
                " class="card-title mouse">
                                    <i class="fas fa-folder m-2 text-primary mr-2"></i>
                                    <a>{{ carpeta }}</a></a>
                            </div>
                            <div *ngIf="!carpeta.endsWith('/')">
                                <a (click)="
                  mandarRuta(carpeta); ocultoButom5 = false; ocultoButom6 = true
                " class="card-title mouse"><i class="fas fa-file-alt ml-1 text-primary mr-2"></i>
                                    <a>{{ carpeta }}</a></a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ocultoButom6">
                        <div class="card-body mouse" *ngFor="let ruta of Rutas">
                            <div *ngIf="ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                            <div *ngIf="!ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="far fa-folder-open m-2 text-primary mr-2"></i><a>{{ ruta | splitPipe }}</a></a><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span *ngIf="completado">
                <div class="text-right">
                    <span (mouseover)="changeText = true" (mouseout)="changeText = false"
                        (click)="rutass = false;  rutaNueva(flota, sentObj.amm); rutasAmm = true; listaStora = false; completado = false; mensajes = false"><i
                            class="fas fa-check-square text-success  fa-3x hove"></i></span>
                    <div class="text-primary font" *ngIf="changeText">{{'presione_continuar'| translate}}</div>
                    <div *ngIf="!changeText"> </div>

                </div>
            </span>

            <span *ngIf="completado2">
                <div class="text-right">
                    <span (mouseover)="changeText = true" (mouseout)="changeText = false"
                        (click)="rutass = false; rutasAmm = true; listaStora = true; listoConfirmado = true; completado2 = false; mensajes = false"><i
                            class="fas fa-check-square text-success  fa-3x"></i></span>
                    <div class="text-primary font" *ngIf="changeText">{{'presione_continuar'| translate}} </div>
                    <div *ngIf="!changeText"> </div>

                </div>
            </span>
            <span *ngIf="servicio.completado3">
                <div class="text-right">
                    <span (click)="rutass = false; servicio.completado3 = false; servicio.mensajes = false"><i
                            class="fas fa-check-square text-success  fa-3x"></i></span>
                    <div class="text-primary font">{{'ocultar' | translate}} </div>
                </div>
            </span>

            <span *ngIf="completado3">
                <div class="text-right">
                    <span (mouseover)="changeText = true" (mouseout)="changeText = false"
                        (click)="rutass = false; rutasAmm = true; listoConfirmadoLista = true; completado3 = false; mensajes = false"><i
                            class="fas fa-check-square text-success  fa-3x"></i></span>
                    <div class="text-primary font" *ngIf="changeText">{{'presione_continuar'| translate}} </div>
                    <div *ngIf="!changeText"> </div>

                </div>
            </span>
        </div>
    </div>




    <div *ngIf="servicio.procesosP">
        <div *ngIf="servicio.loudingProcesos" class="text-center">
            <img src="assets/resources/images/spinner.gif"><br>
            <span class="text-primary font">{{'espere' | translate}}</span>
        </div>
        <div class="row justify-content-center">
            <div class="col-8">
                <div *ngIf="botonProceso" class="card fondo">
                    <div class="card-body scroll- p-4">
                        <div *ngFor="let local of servicio.nuevaListaProcesar">

                            <a class="font">{{'fecha' | translate}} :</a> <a class="text-primary">
                                {{local.date | date: 'medium'}}</a>
                            <br>
                            <a class="font">{{'flota' | translate}} :</a> <a> {{local.flota}}</a> <br>
                            <a class="font">Amm :</a> <a> {{local.pathAMM}}</a> <br>
                            <a class="font">{{'diccionario'| translate}} :</a> <a> {{local.pathDiccionario}}</a> <br>
                            <a class="font">{{'ruta' | translate}} :</a> <a> {{local.pathVersion}}</a> <br>
                            <a class="font">{{'version'|translate}} :</a> <a> {{local.version}}</a> <br>
                            <a class="font">{{'usuario' | translate}} :</a> <a> {{local.user}}</a> <br>
                            <hr>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>