import { EoGuard } from './servicios/eo.guard';
import { GuardsGuard } from './servicios/guards.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './componentes/index/index.component';
import { HomeComponent } from './componentes/home/home.component';
import { PerfilComponent } from './componentes/perfil/perfil.component';
import { EoComponent } from './componentes/eo/eo.component';
import { BuscarComponent } from './componentes/buscar/buscar.component';
import { DescomprimirComponent } from './componentes/descomprimir/descomprimir.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { role: ['load', 'procesing'] },
  },
  {
    path: 'profile',
    component: PerfilComponent,
    data: { role: 'admin' },
    canActivate: [GuardsGuard]
  },
  {
    path: 'search',
    component: BuscarComponent,
    data: { role: 'eo' },
    canActivate: [EoGuard]

  },
  {
    path: 'engineering_order',
    component: EoComponent,
    data: { role: 'eo' },
    canActivate: [EoGuard]
  },
  {
    path: 'engineering_order/:id',
    component: EoComponent,
    data: { role: 'eo' },
    canActivate: [EoGuard]
  },
  {path: 'descomprimir', component: DescomprimirComponent},
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
