export class TecnicalFile {
    tecnicalFile: FileList;
    progress: number;
    id: any;
    name: string;
    acepto: boolean;
    finalizado: boolean;
    error: boolean;
    fallo: boolean;
    usuario: string;

    constructor(
        tecnicalFile: FileList,
        progress: number,
        id: any,
        name: string,
        acepto: boolean,
        finalizado: boolean,
        error: boolean,
        fallo: boolean,
        usuario: string,
       ) {
        this.tecnicalFile = tecnicalFile;
        this.progress = progress;
        this.id = id;
        this.name = name;
        this.acepto = acepto;
        this.finalizado = finalizado;
        this.error = error;
        this.fallo = fallo;
        this.usuario = usuario;
    }
}
