import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EoGuard  {
  private usuario = JSON.parse(sessionStorage.getItem('user'));

  constructor ( private router: Router ) {
    console.log(this.usuario);
  }


  canActivate() {
    if (this.usuario._process.profile.eo) {
      return true;
    } else {
      this.router.navigate(['home']);
       alert('Usuario sin autorizacion');
      return false;
    }
}
}
