import {Injectable} from '@angular/core';

@Injectable()
export class User {

   private _name:string;
   private _mail:string;
   private _token:string;
   private _locale:string;
   public _process:any;
   private _token2:string;

  constructor(){
    this.init();
  }

  public init(){
    this.name='';
    this.mail='';
    this.token='';
    this.locale='';
    this.process='';
    this.token2='';
  }

  set name(value:string) {
    this._name = value
  }

  get name():string {
    return this._name;
  }

  set mail(value:string) {
    this._mail = value
  }

  get mail():string {
    return this._mail;
  }

  set token(value:string) {
    this._token = value
  }

  get token():string {
    return this._token;
  }

  set locale(value:string) {
    this._locale = value
  }

  get locale():string {
    return this._locale;
  }

  set process(value:any) {
    this._process = value
  }

  get process():any {
    return this._process;
  }

  get token2():string{
     return this._token2;
  }

  set token2(value:string){
    this._token2 =value;
  }

}