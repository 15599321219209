<div id="container">
  <app-navbar></app-navbar>
  <div class="white-bg"></div>
  <ul class="nav nav-tabs tabs mt-3 col-11 mar" id="myTab" role="tablist">
    <li class="nav-item mr-1">
      <a (click)="buscarPerfiles(); home = true; perf = false;" class="nav-link active" id="home-tab" data-toggle="tab"
        href="#home" role="tab" aria-controls="home" aria-selected="true" translate>listar</a>
    </li>
    <li class="nav-item">
      <a (click)="perf = true; home = false" class="nav-link" id="profile-tab" data-toggle="tab" href="#profile"
        role="tab" aria-controls="profile" aria-selected="false" translate>crear</a>
    </li>
  </ul>
  <div class="tab-content pb-5" id="myTabContent">

    <div class="fondo-negro animated fadeIn" [ngClass]="serviModal.oculto">

      <div class="modal" style="display: block;" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" translate>actualizar_Usuario</h5>
              <button (click)="cerrarModal()" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">

              <div class="container d-flex col-12 pb-5 mb-5 card">
                <div class="col-12">
                  <form>
                    <div class="form-group d-flex col-12 mt-5">
                      <label for="" class="form-text col-4 p-0">{{ 'nombre_perfil' | translate }}</label>
                      <input type="text" name="nombre perfil" class="form-control col-8" id="" aria-describedby=""
                        placeholder="Email" [(ngModel)]="nombrePerfil" required>
                    </div>

                    <div class="form-group d-flex col-12 mt-5">
                      <label for="" class="form-text col-4 p-0">{{ 'estado' | translate }} :</label>
                      <select [(ngModel)]="estado" required class="form-control col-8" aria-describedby=""
                        name="estado">
                        <option *ngFor="let estad of estados" [value]="estad.codigo"> {{ estad.nombre }} </option>
                      </select>
                    </div>

                    <div class="form-group d-flex col-12 mt-5">
                      <label for="" class="form-text col-4 p-0">{{ 'roll_Perfil' | translate }} :</label>
                      <select [(ngModel)]="perfil" required class="form-control col-8" aria-describedby=""
                        name="roll perfil">
                        <option *ngFor="let perfil of perfiles" [value]="perfil.codigo"> {{ perfil.nombre }} </option>
                      </select>
                    </div>

                    <button type="button" (click)="actualizar(); cerrarModal() "
                      class="col-4 btn btn-success btn-block perfil mt-5">{{ 'actualizar' | translate }} </button>
                  </form>
                </div>
              </div>


            </div>
            <div class="modal-footer">
              <button (click)="cerrarModal()" type="button" class="btn btn-secondary">{{'cerrar'| translate}}</button>
            </div>
          </div>
        </div>
      </div>

    </div>



    <div *ngIf="loudingImg" class="text-center mt-5">
      <img src="assets/resources/images/spinner.gif"><br>
      <span class="text-primary font">{{'actualiza_perfil'| translate}}</span>
    </div>
    <div *ngIf="loudingImgBusca" class="text-center">
      <img src="assets/resources/images/spinner.gif"><br>
      <span class="text-primary font">{{'busca_perfil'| translate}}</span>
    </div>
    <div *ngIf="loudingImgCrea" class="text-center">
      <img src="assets/resources/images/spinner.gif"><br>
      <span class="text-primary font">{{'crea_perfil'| translate}}</span>
    </div>
    <div *ngIf="loudingImgElimina" class="text-center">
      <img src="assets/resources/images/spinner.gif"><br>
      <span class="text-primary font">{{'elimina_perfil'| translate}}</span>
    </div>
    <div *ngIf="home" class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <div *ngIf="muestraTabla">
        <div class="col-12 m-4 text-center">
          <h1 translate>titulo_perfil</h1>
        </div>
        <div class="col-8 offset-2 mb-5 pb-5">
          <table class="table table-hover dataTable dtr-inline text-center table-striped" style="width: 100%;">
            <thead>
              <tr class="bg-latam text-white">
                <th class="text-center dt-body-center">{{'usuario'| translate}}</th>
                <th class="text-center dt-body-center">{{'perfil'| translate}}</th>
                <th class="text-center dt-body-center">{{'estado'| translate}}</th>
                <th class="text-center dt-body-center" translate>editar</th>
                <th class="text-center dt-body-center" translate>eliminar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let usuario of usuarios">
                <td class="dt-body-center">{{usuario['correo']}}</td>
                <td class="dt-body-center" *ngIf="usuario['idPerfilCC'] === 1">Administrador</td>
                <td class="dt-body-center" *ngIf="usuario['idPerfilCC'] === 2">Publicaciones</td>
                <td class="dt-body-center" *ngIf="usuario['idPerfilCC'] === 3">Lectura</td>
                <td class="dt-body-center" *ngIf="usuario['idPerfilCC'] === 4">Eo</td>
                <td class="dt-body-center text-success" *ngIf="usuario['estado'] === 1"><i class="fas fa-user"></i></td>
                <td class="dt-body-center text-secondary" *ngIf="usuario['estado'] === 0"><i class="fas fa-user-times"></i>
                </td>
                <td (click)="pasarParametros(usuario['id'], usuario['correo'], usuario['idPerfilCC'], usuario['estado']); "
                  class="dt-body-center text-warning"><i class="far fa-edit fa-2x"></i></td>
                <td (click)="deletePerfil(usuario['id'], usuario['correo'], usuario['idPerfilCC'])"
                  class="dt-body-center text-danger"><i class="fas fa-trash-alt fa-2x"></i></td>
              </tr>
            </tbody>
            <tfoot>

            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="perf" class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
      <div class="col-12 mt-4 text-center">
        <h2 class="col-12" id="logo"> {{ 'crear' | translate }} {{ 'perfil' | translate }}</h2>
      </div>
      <div class="container d-flex col-6 pb-5 mb-5 card bg-light">
        <div class="col-12">
          <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div class="form-group d-flex col-12 mt-5">
              <label for="" class="form-text col-4 p-0">{{ 'nombre_perfil' | translate }}</label>
              <input type="email" name="nombre_perfil" #nombrePerfil class="form-control col-6" id=""
                aria-describedby="" placeholder="Email" #nombre_perfil ngModel required
                pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}">
            </div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-6">
                <div class="text-center marg" *ngIf="f.touched && f.form.controls?.nombre_perfil?.errors?.required">
                  <span class="text-danger f-weight">{{'campo_requerid'| translate}}</span>
                </div>
                <div class="text-center marg" *ngIf="f.form.controls?.nombre_perfil?.errors?.pattern">
                  <span class="text-danger f-weight">{{'formato_correo'| translate}}</span>
                </div>
              </div>
            </div>
            <div class="form-group d-flex col-12 mt-5">
              <label>{{ 'roll_Perfil' | translate }} :</label>
              <select ngModel required type="number" class="form-control col-6" aria-describedby="" name="roll_perfil">
                <option value="">{{'seleccione_perfil'| translate}}</option>
                <option *ngFor="let perfil of perfiles" [value]="perfil.codigo"> {{ perfil.nombre }} </option>
              </select>
            </div>

            <button [disabled]="f.invalid" type="submit"
              class="col-4 btn btn-success btn-block perfil mt-5">{{ 'crear' | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>