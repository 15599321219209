<div class="mt-5">

  <div class="row">
    <div class="col-lg-6 col-sm-12">
      <div class="card fondo">
        <div class="card-header text-center">
          <h5>{{'busca_archivos'| translate}} <i class="fas fa-folder ml-5 text-primary "></i></h5>
        </div>
        <div class="card-body">
          <form action="">
            <div class="form-group d-flex justify-content-between text-right">
              <input type="text" disabled="disabled" placeholder="{{'lista_archivos'| translate}}"
                [(ngModel)]="pathSourceFiles" class="form-control mb-3 tamano" name="sourceFiles">
              <button *ngIf="process.profile.obtenerCarpetas" class="btn btn-outline-primary botones carga"
                (click)="getCarpetas(); pathSourceFiles = ''; rutass = true; rutas1 = true;path1 = [];oculto = true; ocultarFlota= false">
                <i class="fas fa-folder"></i> </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-sm-12">
      <div *ngIf="rutass">
        <span *ngIf="botonRegresar1">
          <a (click)="getCarpetas(); pathSourceFiles = ''; rutass = true; rutas1 = true; path1 = []; oculto = true; ocultoButom1 = false;
             ocultarFlota= false"><i class="fas fa-angle-double-down text-primary m-2"></i></a>
        </span>

        <span *ngFor="let pat of path1; let i = index">
          <span *ngIf="pat.endsWith('/')">
            <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat)"
              class="hover mouse"><a>{{pat | splitPipe}}</a><i class="fas fa-folder ml-1 text-primary mr-2"></i></a>
          </span>
          <span *ngIf="!pat.endsWith('/')">
            <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat)"
              class="hover mouse"><a>{{pat | splitPipe}}</a><i class="fas fa-file-alt ml-1 text-primary mr-2"></i></a>
          </span>
        </span>

        <div class="card fondo scroll-">
          <div *ngIf="louding" class="text-center text-primary m-5">
            <i class=" text-center fas fa-sync fa-spin fa-4x"></i>
            <p class=" text-center mt-3">{{'espere' | translate}}</p>
          </div>

          <div *ngIf="oculto">

            <div class="card-body mouse" *ngFor="let carpeta of carpetas">
              <div *ngIf="carpeta.endsWith('/')">
                <a (click)="mandarRuta(carpeta); oculto = false; ocultarFlota = true" class="card-title mouse"> <i
                    class="fas fa-folder text-primary mr-2"></i><a>{{carpeta}}</a> </a>
              </div>

              <div *ngIf="!carpeta.endsWith('/')">
                <a target="_blank" download href="{{urlDocumentControl}}{{carpeta}}"
                   (click)="mandarRuta(carpeta)" class="card-title mouse"> <i
                  class="fas fa-file-alt ml-1 text-primary mr-2"></i><span class="borde mb-1">{{carpeta}}</span></a>
              </div>

            </div>
          </div>

          <div *ngIf="ocultarFlota">

            <div class="card-body mouse" *ngFor="let ruta of rutas">
              <div *ngIf="ruta.endsWith('/')">
                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                    class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ruta | splitPipe}}</a></a><br>
              </div>
              <!--
              <div *ngIf="!ruta.endsWith('/')">
                <a target="_blank" download href="{{urlStorage}}/{{DownloadStorage}}/{{ruta}}"
                  (click)="mandarRuta(ruta)" class="card-title"> <i
                    class="fas fa-file-alt ml-1 text-primary mr-2"></i><span
                    class="borde mb-1">{{ruta | splitPipe}}</span></a><br>
              </div>
              -->

              <div *ngIf="!ruta.endsWith('/')">
                <a target="_blank" download href="{{urlDocumentControl}}{{ruta}}"
                   (click)="mandarRuta(ruta)" class="card-title"> <i
                  class="fas fa-file-alt ml-1 text-primary mr-2"></i><span
                  class="borde mb-1">{{ruta | splitPipe}}</span></a><br>
              </div>

            </div>
          </div>
        </div>

        <span>
          <div class="text-right">
            <span (mouseover)="changeText = true" (mouseout)="changeText = false"
              (click)="rutass = false; completado2 = false; mensaje = false; pathSourceFiles = ''"><i
                class="fas fa-check-square text-success  fa-3x"></i></span>
            <div class="text-primary font" *ngIf="changeText">{{'salir'| translate}} </div>
            <div *ngIf="!changeText"> </div>
          </div>
        </span>
      </div>
    </div>
