import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DecompressRequest } from '../modelo/DecompressRequest';
import { DecompressResponse } from '../modelo/DecompressResponse';
import { environment } from 'src/environments/environment';
import { RutaRequest } from '../modelo/RutaRequest';
import { RutaResponse } from '../modelo/RutaResponse';
@Injectable({
  providedIn: 'root'
})
export class ServiceDecompressService {
  constructor(private http: HttpClient) { }
  Url = environment.ENDPOINT + 'esignaturebff/generatedecompress';

  descomprimirService(req: DecompressRequest) {
    return this.http.post<DecompressResponse>(this.Url, req);
  }

}
