import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ErrorServiceService {

  constructor() { }

  show(mensaje) {
console.log(mensaje);
   Swal.fire({
      type: 'error',
      title : 'En estos momentos no podemos realizar su solicitud, por favor intentelo mas tarde..!',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
  });
}
}
