import { PermisosService } from './permisos.service';
import { Profile } from './../modelo/profile';
import { User } from './../interfaces/user.service';

import { Injectable  } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GuardsGuard {

  private usuario = JSON.parse(sessionStorage.getItem('user'));

  constructor ( private router: Router) {
  }


  canActivate() {
    if (this.usuario._process.profile.admin) {
      return true;
    } else {
      this.router.navigate(['home']);
      alert('Usuario sin autorizacion');
      return false;
    }
  }
         }

