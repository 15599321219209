import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EngineeringOrderService {

  constructor(private http: HttpClient) { }

  validateCode(code: string): Observable<any> {
    let data ={
        "action": "validate",
        "identification": {
            "code": code
        }
    }
	
    console.log("REQUEST SEND.."+environment.ENDPOINT+"esignaturebff/validateCard - MXI ",JSON.stringify(data));
    return this.http.post(environment.ENDPOINT + "esignaturebff/validateCard", data);    
  }

  jobCardDefinition(code: string, flota: string, assembly: string): Observable<any> {
     let data ={
       "jic_code": code,
       "flota": flota,
       "assembly": assembly
     }
     console.log("REQUEST SEND.."+environment.ENDPOINT+"esignaturebff/getJobCardDefinition - MXI ",JSON.stringify(data));
     return this.http.post(environment.ENDPOINT+"esignaturebff/getJobCardDefinition", data);   
   }

  sendOrder(obj,code){
    console.log();
    console.log(obj.panelCodes);
    if(obj.zoneCodes!=undefined&&obj.zoneCodes.length>0){
      obj["zoneList"]={"zoneCode":obj.zoneCodes};
      delete obj.zoneCodes;
    }
    if(obj.panelCodes!=undefined&&obj.panelCodes.length>0){
      obj["panelList"]={"panelCode":obj.panelCodes};
       delete obj.panelCodes; 
    }
    obj["jobCardStepList"]={"jobCardStep":obj.jobCardSteps}
    delete obj["jobCardSteps"];
    obj.jobCardStepList.jobCardStep.forEach((element,index) => {
      element.order=index+1;
      element["certifyingSkillList"]={"certifyingSkill":element.certifyingSkills};
      delete element.certifyingSkills;
      if(obj.jobCardStepList.jobCardStep.length==(index+1)){
        return obj;
      }
    });
    
    let data ={
      "action": "update",
      "identification": {
         "code":code,
         "type": {
          "configSlot": {
            "assemblyCode": obj.assemblyId,
            "configSlotCode": null
          },
          "partNumber": null
        }
      },
      "details": {
        "name": obj.name,
        "description": obj.description,
        "documentReference": obj.documentReference,
        "instructions":obj.instructions
     },
     "zoneList": obj["zoneList"],
     "panelList":obj["panelList"],
     "jobCardStepList": obj["jobCardStepList"]
    }

    console.log("REQUEST SEND.."+environment.ENDPOINT+"esignaturebff/updateCard - MXI ",JSON.stringify(data));
    return this.http.post(environment.ENDPOINT + "esignaturebff/updateCard", data);

  }
}
