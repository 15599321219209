import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {

  httpOptionsAccessCors: any;

  urlPerfil = environment.ENDPOINT + 'esignaturebff/selectPerfilCorreo/';
  urlCrearPerfil = environment.ENDPOINT + 'esignaturebff/insertPerfil';
  urlBuscarPerfiles = environment.ENDPOINT + 'esignaturebff/selectPerfilAll';
  urlDelete = environment.ENDPOINT + 'esignaturebff/deletePerfil';
  urlActualizar = environment.ENDPOINT + 'esignaturebff/updatePerfil';

  constructor(private http: HttpClient) {
  }

  permisos(email: any) {
    const correo = email;
    return this.http.get(this.urlPerfil + correo);
  }

  crearPerfil(Perfilamiento: any) {
    return this.http.post(this.urlCrearPerfil, Perfilamiento);
  }

  buscarPerfiles() {
    return this.http.get(this.urlBuscarPerfiles);
  }

  deletePerfil(perfilamiento: any) {
    const headers = { headers: new HttpHeaders({ 'Content-type': 'application/json' }), body: perfilamiento };
    return this.http.delete(this.urlDelete, headers);
  }

  actualizarPerfil(perfilamiento: any) {
    const headers = { headers: new HttpHeaders({ 'Content-type': 'application/json' }) };
    return this.http.post(this.urlActualizar, perfilamiento, headers);
  }
}
