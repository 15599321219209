import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const verificateUrl = req.url.includes('esignaturebff/selectPerfilCorreo/');
        if (verificateUrl) {
          return next.handle(req);
        } else {
            const currentUser = JSON.parse(sessionStorage.getItem('user'));
            const token = sessionStorage.getItem('id_token');
            if (req.url.includes(environment.ENDPOINT)) {
                const copy = req.clone({
                    headers: new HttpHeaders({
                        'Access-Control-Allow-Origin': '*',
                        'origen': 'eSignatureWEB',
                        'username': currentUser._name,
                        'useremail': currentUser._mail,
                        'authorization': 'Bearer ' + token
                    })
                });
                // console.log("BasicAuthInterceptor..copy->",copy);
                return next.handle(copy);
            }
            // console.log("BasicAuthInterceptor..req->",req);
            return next.handle(req);    }
        }
}
