import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

@Injectable()
export class MessageService {

  constructor(private translate: TranslateService) {
  }

  basicSwal(mensaje: any) {
      return swal.fire({
        title: this.translate.instant(mensaje)
      });
  }

  loadingTransparent() {
    return swal.fire({
      allowOutsideClick: () => !swal.isLoading(),
      background: 'transparent',
      onOpen: () => {
        swal.showLoading();
      }
    });
  }

  close() {
    return swal.close();
  }

  exito(mensaje: any) {
    return swal.fire({
      title: this.translate.instant(mensaje),
      type: 'success',
      timer: 1000,
      allowOutsideClick: () => false,
      showConfirmButton: false
    });
  }

  mensajeCustom(mensajes: Array<string>) {
    let mensajeFinal = '';
    mensajes.forEach(value => {
        const helper = this.translate.instant(value);
        mensajeFinal = mensajeFinal + helper;
    });
    return swal.fire({
      title: mensajeFinal,
      type: 'success',
      allowOutsideClick: () => false,
      showConfirmButton: true
    });
  }

  error(mensaje: any) {
    return swal.fire({
      title: this.translate.instant(mensaje),
      type: 'error',
      timer: 3000,
      allowOutsideClick: () => false,
      showConfirmButton: false
    });
  }
}
