import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcesarService {

  constructor(private http: HttpClient) { }


  getDocuments(fleet:string):Observable<any>{ 
    let data = {
      "flota": fleet
    } 

    console.log("REQUEST SEND.getDocuments."+environment.ENDPOINT+"esignaturebff/findPublication", JSON.stringify(data));
    return this.http.post(environment.ENDPOINT+"esignaturebff/findPublication", data);
  };
  
  enviarProcesar(flota:string, amm:string, dic:string, pathVersion:string, version:string, tags?:string): Observable<any> {

    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "flota":flota,
      "pathFilesAMM": amm,
      "pathFileDIC": dic,
      "pathVersion": pathVersion,
      "version": version
    }

    if (flota == "B787"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturebff/generateFlota787 ",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturebff/generateFlota787", data); 
    }else if (flota == "B777"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturebff/generateFlota777 ",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturebff/generateFlota777", data); 
    }else if (flota == "B767"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturebff/generateFlota767 ",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturebff/generateFlota767", data); 
    }else if (flota == "A320"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturebff/generateFlota320 ",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturebff/generateFlota320", data); 
    }else if (flota == "A350"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturebff/generateFlota350 ",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturebff/generateFlota350", data); 
    }
  }
}
