<div class="mt-5">

    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <div class="card fondo">
                <div class="card-header text-center">
                    <h5>{{'descom_archivos'| translate}} <i class="fas fa-folder ml-5 text-primary "></i></h5>
                </div>
                <div class="card-body">
                    <form action="">
                        <div class="form-group d-flex justify-content-between text-right">
                            <input type="text" disabled="disabled" placeholder="{{'archivos_fuentes'| translate}}"
                                [(ngModel)]="pathSourceFiles" class="form-control mb-3 tamano" name="sourceFiles">
                            <button *ngIf="process.profile.obtenerCarpetas" class="btn btn-outline-primary botones carga"
                                (click)="getCarpetas(); mensaje = false; pathSourceFiles = ''; rutass = true; rutas1 = true; rutas2=false;path1 = []; path2 = [];oculto = true; ocultoButom1 = false; ocultoButom2 = false; ocultarFlota= false">
                                <i class="fas fa-folder"></i> </button>
                        </div>
                        <div class="form-group d-flex justify-content-between text-right">
                            <input type="text" disabled="disabled" placeholder="{{'archivos_destinos'| translate}}"
                                [(ngModel)]="pathDestinationFiles" class="form-control mb-3 tamano"
                                name="destinationFiles">
                            <button  *ngIf="process.profile.obtenerCarpetas" class="btn btn-outline-primary botones carga"
                                (click)="getCarpetas(); mensaje = false; pathDestinationFiles = ''; rutass = true; rutas2 = true; rutas1 =false;path2 = []; path1 = []; ocultoButom1 = true; ocultoButom2= false; oculto = false; ocultarFlota = false">
                                <i class="fas fa-folder"></i></button>

                        </div>
                        <div justify-content-between>
                            <button  *ngIf="process.profile.cargarArchivos"
                                (click)=" rutass = false; descomprimir(); guardarDatos(); services.getStorageDes(); mueveReloj()"
                                class="btn btn-outline-primary">{{'descomprimir'| translate}}</button>

                                <div *ngIf="process.profile.CrearCarpetas" class="text-center procesar">
                                    <button (click)="abrirModal()" class="btn btn-primary carga">{{'crea_carpeta'| translate}}</button>
                                </div>

                                <div *ngIf="process.profile.visitarProcesos">
                            <div *ngIf="botonProceso" class="text-right procesar ">
                                <span *ngIf="!services.procesosD" class="boton procesar">
                                    <button class="btn btn-primary" type="button" (click)="verProcesos()"><i
                                            class="fas fa-eye mr-2"></i>{{ 'ver_procesos'| translate}}</button>
                                </span>
                                <span *ngIf="services.procesosD" class="boton procesar">
                                    <button class="btn btn-primary" type="button" (click)="OcultarProcesos()"><i
                                            class="fas fa-eye-slash mr-2"></i>{{'ocultar_procesos' | translate}}</button>
                                </span>
                            </div>
                        </div>
                        </div>
                    </form>

                </div>

            </div>
        </div>

        <div class="col-lg-6 col-sm-12">
            <div *ngIf="mensaje">
                <div class="card fondo">
                    <div class="card-body">
                        <div class="text-right font">
                            {{diasSemana[dia.getDay()]}} {{ dia.getDate()}} {{meses[dia.getMonth()]}}
                            {{dia.getFullYear()}} <br> {{hora}}:{{minuto}}:{{segundo}} </div><br>
                        <h4>Archivos Descomprimidos : </h4>
                        <span class="font">Origen :</span> {{descomp.pathSourceFiles }} <br>
                        <span class="font">Destino :</span> {{descomp.pathDestinationFiles }} <br>
                        <span class="font">Usuario :</span> {{user._name}}
                    </div>
                </div>
            </div>





            <div *ngIf="rutass">
                <span *ngIf="botonRegresar1">
                    <a (click)="getCarpetas(); mensaje = false; pathSourceFiles = ''; rutass = true; rutas1 = true;
                     rutas2=false;path1 = []; path2 = [];oculto = true; ocultoButom1 = false;
                      ocultoButom2 = false; ocultarFlota= false"><i
                            class="fas fa-angle-double-down text-primary m-2"></i></a>
                </span>
                <span *ngIf="botonRegresar2">
                    <a (click)="getCarpetas(); mensaje = false; pathDestinationFiles = ''; rutass = true; 
                     rutas2 = true; rutas1 =false;path2 = []; path1 = []; ocultoButom1 = true; ocultoButom2= false; 
                     oculto = false; ocultarFlota = false"> <i
                            class="fas fa-angle-double-down text-primary m-2"></i></a>
                </span>

                <span *ngFor="let pat of path1; let i = index">
                    <span *ngIf="pat.endsWith('/')">
                        <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{pat | splitPipe}}</a><i
                                class="fas fa-folder ml-1 text-primary mr-2"></i></a>
                    </span>
                    <span *ngIf="!pat.endsWith('/')">
                        <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{pat | splitPipe}}</a><i
                                class="fas fa-file-alt ml-1 text-primary mr-2"></i></a>
                    </span>
                </span>


                <span *ngFor="let pat of path2; let i = index">
                    <span *ngIf="pat.endsWith('/')">
                        <a *ngIf="rutas2" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{pat | splitPipe}}</a><i
                                class="fas fa-folder ml-1 text-primary mr-2"></i></a>
                    </span>
                    <span *ngIf="!pat.endsWith('/')">
                        <a *ngIf="rutas2" (click)="devolverRuta(i); mandarRuta(pat)"
                            class="hover mouse"><a>{{pat | splitPipe}}</a><i
                                class="fas fa-file-alt ml-1 text-primary mr-2"></i></a>
                    </span>
                </span>



                <div class="card fondo scroll-">
                    <div *ngIf="louding" class="text-center text-primary m-5">
                        <i class=" text-center fas fa-sync fa-spin fa-4x"></i>
                        <p class=" text-center mt-3">{{'espere' | translate}}</p>
                    </div>

                    <div *ngIf="oculto">

                        <div class="card-body mouse" *ngFor="let carpeta of carpetas">
                            <div *ngIf="carpeta.endsWith('/')">
                                <a (click)="mandarRuta(carpeta); oculto = false; ocultarFlota = true"
                                    class="card-title mouse"> <i
                                        class="fas fa-folder text-primary mr-2"></i><a>{{carpeta}}</a> </a>
                            </div>

                            <div *ngIf="!carpeta.endsWith('/')">
                                <a (click)="mandarRuta(carpeta);"
                                    class="card-title mouse"> <i
                                        class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{carpeta}}</a></a>
                            </div>


                        </div>
                    </div>

                    <div *ngIf="ocultarFlota">

                        <div class="card-body mouse" *ngFor="let ruta of rutas">
                            <div *ngIf="ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ruta | splitPipe}}</a></a><br>
                            </div>
                            <div *ngIf="!ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"> <i
                                        class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{ruta | splitPipe}}</a></a><br>
                            </div>
                        </div>
                    </div>



                    <div *ngIf="ocultoButom1">

                        <div class="card-body mouse" *ngFor="let carpeta of segundaCarpeta">
                            <div *ngIf="carpeta.endsWith('/')">
                                <a (click)="mandarRuta(carpeta); ocultoButom1 = false; ocultoButom2 = true"
                                    class="card-title mouse"> <i class="fas fa-folder m-2 text-primary mr-2"></i>
                                    <a>{{carpeta}}</a></a>
                            </div>
                            <div *ngIf="!carpeta.endsWith('/')">
                                <a (click)="mandarRuta(carpeta);"
                                    class="card-title mouse"><i
                                        class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{carpeta}}</a></a>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="ocultoButom2">

                        <div class="card-body mouse" *ngFor="let ruta of rutas">
                            <div *ngIf="ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ruta | splitPipe}}</a></a><br>
                            </div>
                            <div *ngIf="!ruta.endsWith('/')">
                                <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                        class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{ruta | splitPipe}}</a></a><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span *ngIf="completado">
                <div class="text-right">
                    <span (mouseover)="changeText = true" (mouseout)="changeText = false"
                        (click)="rutass = false; completado = false; mensaje = false"><i
                            class="fas fa-check-square text-success  fa-3x"></i></span>
                    <div class="text-primary font" *ngIf="changeText">{{'presione_continuar'| translate}} </div>
                    <div *ngIf="!changeText"> </div>
                </div>
            </span>

            <span *ngIf="completado2">
                <div class="text-right">
                    <span (mouseover)="changeText = true" (mouseout)="changeText = false"
                        (click)="rutass = false; completado2 = false; mensaje = false"><i
                            class="fas fa-check-square text-success  fa-3x"></i></span>
                    <div class="text-primary font" *ngIf="changeText">{{'ocultar'| translate}} </div>
                    <div *ngIf="!changeText"> </div>
                </div>
            </span>

        </div>
    </div>

    <div *ngIf="services.procesosD">
        <div *ngIf="services.loudingProcesos" class="text-center">
            <img src="assets/resources/images/spinner.gif"><br>
            <span class="text-primary font">{{'espere' | translate}}</span>
        </div>
        <div class="row justify-content-center">
            
            <div class="col-8">
                <div class="card fondo">
                    <div class="card-body scroll- p-4">
                        <div *ngFor="let local of services.nuevaLista">

                            <span class="font">{{'fecha' | translate}} : </span> <span class="text-primary">
                                {{local.date | date: 'medium'}}</span> <br>
                            <span class="font">{{'origen'| translate}} : </span> {{local.pathSourceFiles}} <br>
                            <span class="font">{{'destino'| translate}} : </span> {{local.pathDestinationFiles}} <br>
                            <span class="font">{{'usuario' | translate}} : </span> {{local.user}}
                            <hr>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


</div>