import { Orders } from './../../modelo/order';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { EDocService } from './../../servicios/e-doc.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

//import { MatTableDataSource } from "@angular/material";

@Component({
  selector: 'app-e-doc',
  templateUrl: './e-doc.component.html',
  styleUrls: ['./e-doc.component.css'],
})
export class EDocComponent implements OnInit {
  user = JSON.parse(sessionStorage.getItem('user'));
  ordenDate: Orders = new Orders();
  ordenTally: Orders = new Orders();
  ordenRFI: Orders = new Orders();
  salir = true;
  Works_date: any = '';
  Works_tally: any = '';
  Works_RFI: any = '';
  worksDate: [] = [];
  worksTally: [] = [];
  worksRFI: [] = [];
  date = false;
  tally = false;
  rfi=false;
  loading = false;
  recSingleLoading = false;
  sendDisabled = true;
  mensajeDate = false;
  mensajeTally = false;
  butonConsulta = true;
  completado = false;
  today: Date;
  dia: any;
  mes: any;
  anio: any;
  spli: any;
  displayedColumns: ["barcode", "erase"];
  public barcodes: string[] = [];
  barcode: string;
  clicked = false;
  //dataSource = new MatTableDataSource<string>(this.barcodes);
  fechaNoValida = false;

  barInput: string = "";
  barcodeRows: any[] = [];
/**/
  barcodeTypes: any[] = [
    {value: 'taskcard', viewValue: 'Taskcard'},
    {value: 'tally', viewValue: 'Tally'},
    {value: 'rfi', viewValue: 'Rfi'},
  ];

  barcodeList: any[] = [];

  newBarcode = "";
  typeSelected: any;

  constructor(private servi: EDocService, private translateService: TranslateService) {
    this.today = new Date();
    this.dia = this.today.getDate();
    this.mes = this.today.getUTCMonth() + 1;
    this.anio = this.today.getFullYear();
  }

  ngOnInit() {
  }



  consultaWorkDate() {
    this.fechaNoValida = false;
    if (this.Works_date === '') {
      swal.fire({
        type: 'error',
        title: this.translateService.instant('selecciona_fecha'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
      });
      return;
    }
    this.spli = this.Works_date.split('-');

    this.validarFecha();
    if ( this.fechaNoValida) {
      return;
    }

    const date = this.Works_date.toString();
   const resul = date.replace('-', '');
   const resul2 = resul.split('-');
   const resul3 = resul2[0] + resul2[1];
   const resultCompleto = resul3.toString();
   this.ordenDate = {
     user: JSON.parse(sessionStorage.getItem('user'))._mail,
     date: resultCompleto
   };
   console.log(this.ordenDate);
   this.completado = false;
   this.butonConsulta = false;
   this.date = false;
   this.tally = false;
   this.worksDate = [];
   this.loading = true;
    this.servi.consultaDate(this.ordenDate).subscribe((data: any) => {
      this.Works_date = '';
      this.butonConsulta = true;
      swal.fire({
        type: 'success',
        title: this.translateService.instant('notificacion' ) + ' ' + this.user._mail + ' ' + this.translateService.instant('completado'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
      });
      this.loading = false;
    }, error => {
      this.loading = false;
      this.Works_date = '';
      this.butonConsulta = true;
    });
  }

  consultaWorkTally() {
    this.fechaNoValida = false;
    if (this.Works_tally === '') {
      swal.fire({
        type: 'error',
       title: this.translateService.instant('selecciona_fecha'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
      });
      return;
    }
    this.spli = this.Works_tally.split('-');

    this.validarFecha();
    if ( this.fechaNoValida) {
      return;
    }
    const date = this.Works_tally.toString();
    const resul = date.replace('-', '');
    const resul2 = resul.split('-');
    const resul3 = resul2[0] + resul2[1];
    const resultCompleto = resul3.toString();
    this.ordenTally = {
      user: JSON.parse(sessionStorage.getItem('user'))._mail,
      date: resultCompleto
    };
    this.completado = false;
    this.butonConsulta = false;
    this.tally = false;
    this.date = false;
    this.worksTally = [];
    this.loading = true;
    this.servi.consultaTally(this.ordenTally).subscribe((data: any) => {
      this.Works_tally = '';
      this.butonConsulta = true;
      swal.fire({
        type: 'success',
        title: this.translateService.instant('notificacion' ) + ' ' + this.user._mail + ' ' + this.translateService.instant('completado'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
      });
        }, error => {
      this.Works_tally = '';
      swal.fire({
        type: 'error',
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
        this.butonConsulta = true;
      });

    });
  }

  consultaRFIDate() {
    this.fechaNoValida = false;
    if (this.Works_RFI === '') {
      swal.fire({
        type: 'error',
        title: this.translateService.instant('selecciona_fecha'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
      });
      return;
    }
    this.spli = this.Works_RFI.split('-');

    this.validarFecha();
    if ( this.fechaNoValida) {
      return;
    }
    const date = this.Works_RFI.toString();
   const resul = date.replace('-', '');
   const resul2 = resul.split('-');
   const resul3 = resul2[0] + resul2[1];
   const resultCompleto = resul3.toString();
   this.ordenRFI = {
    user: JSON.parse(sessionStorage.getItem('user'))._mail,
    date: resultCompleto
  };
   this.completado = false;
   this.butonConsulta = false;
   this.rfi = false;
   this.date = false;
   this.worksRFI = [];
   this.loading = true;
    this.servi.consultaRFI(this.ordenRFI).subscribe((data: any) => {
      this.Works_RFI = '';
      this.butonConsulta = true;
      swal.fire({
        type: 'success',
        title: this.translateService.instant('notificacion' ) + ' ' + this.user._mail + ' ' + this.translateService.instant('completado'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
      });
      this.loading = false;
        }, error => {
      this.Works_RFI = '';
      this.loading = false;
      this.butonConsulta = true;
    });
  }

  validarFecha() {
    const a = parseInt(this.spli[0]);
    const m = parseInt(this.spli[1]);
    const d = parseInt(this.spli[2]);

    const aActual = parseInt(this.anio);
    const mActual = parseInt(this.mes);
    const dActual = parseInt(this.dia);
    
    const fechaActual = new Date(aActual,mActual,dActual);
    const fechaConsultada = new Date(a,m,d);
    
    if(fechaActual < fechaConsultada) {
      swal.fire({
        type: 'error',
        title: this.translateService.instant('fecha_mayor'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.loading = false;
      });
      this.fechaNoValida = true;
    }

  }

  addBarcode(){
    if(this.barcodeRows.includes(this.barInput)){
      Swal.fire({
        title: "Warning",
        text: "Repeated barcode",
        type: "warning",
        confirmButtonText: 'Continuar'
        });
    }
    else if(this.barcodeRows.length >= 20 ){
      Swal.fire({
        title: "Warning",
        text: "20 barcodes max",
        type: "warning",
        confirmButtonText: 'Continuar'
        });
    }
    else if(this.barInput.length > 0){
      this.barcodeRows.push(this.barInput);
      this.barInput = '';
    }
  }

  delBarcode(index){

    Swal.fire({
      title: "Warning",
      text: "Delete barcode " + this.barcodeRows[index] + " ?",
      type: "warning",
      confirmButtonText: 'Delete',
      showCancelButton: true,
      cancelButtonText: 'Cancel'
      }).then((result) => {
        if(result.value){
          this.barcodeRows.splice(index, 1);
        }
      });
  }

  sendBarcodes(){
    console.log(this.barcodeRows);
    this.servi.procesarBarcodes(this.barcodeRows).subscribe((data: any) => {
      console.log(data);
      this.barcodeRows = [];
      Swal.fire({
        title: "Success",
        text: "Barcodes sent",
        type: "success"
        }).then( () => {
          console.log("success ?")
        });
      
    }, error => {
      Swal.fire({
        title: "Error",
        text: "Try again later",
        type: "error"
        }).then(function() {
          console.log('service error');
        });
    });

  }

  /*  v2 */
  addBarcodeToList(){

    let exists = this.barcodeList.filter(obj => {
      return obj.barcode === this.newBarcode;
    });
    
    console.log(exists);

    console.log(this.newBarcode, this.typeSelected);
    if(this.newBarcode == null || this.typeSelected == null || this.newBarcode == ''){
      Swal.fire({
        title: "Warning",
        text: "Verify Type and Barcode",
        type: "warning"
        });
    }
    else if(this.barcodeList.length >= 20){
      Swal.fire({
        title: "Warning",
        text: "20 barcodes",
        type: "warning"
        });
    }
    else if(exists.length){
      Swal.fire({
        title: "Warning",
        text: "Duplicated Barcode",
        type: "warning"
        });
    }
    else{
      this.sendDisabled = true;
      this.barcodeList.push({barcode: this.newBarcode, type: this.typeSelected, statusCode: 0, ignoreValidation: null});
    }

    console.log(this.barcodeList);
  }

  removeBarcodeFromList(index){
    this.barcodeList.splice(index, 1);
    console.log(this.barcodeList.length);
    if(this.barcodeList.length < 1)
      this.sendDisabled = true;
  }

  validateBarcodeList(){
    if(!(this.barcodeList.length == 0)){
      this.recSingleLoading = true;

      this.servi.validateBarcodeList(this.barcodeList).subscribe((data: any) => {
        console.log(data, "hi1");
        this.barcodeList = data;
        Swal.fire({
          text: "Validation Requested",
          type: "info"
          }).then( () => {
            this.recSingleLoading = false;
            console.log("success ?")
          });
          console.log(this.barcodeList, "replaced");
          for(const element of this.barcodeList) {
            element.ignoreValidation = false;
          }
        this.sendDisabled = false;
      }, error => {
        Swal.fire({
          title: "Error",
          text: "Try again later",
          type: "error"
          }).then(function() {
            this.recSingle = false;
            console.log('service error');
          });
      });
    }
    else{
      Swal.fire({
        title: "Error",
        text: "No Barcodes to validate",
        type: "error"
        }).then(function() {
          console.log('service error');
        });
    }
  }

  sendBarcodesList(){

    let validates = true;
    this.barcodeList.forEach( item => {
      if(item.statusCode != 1 && !item.ignoreValidation){
        validates = false;
      }
    });

    if(this.barcodeList.length < 0){
      Swal.fire({
        title: "Error",
        text: "No barcodes",
        type: "error"
        });
    }
    else if(!validates){
      Swal.fire({
        title: "Error",
        text: "Check validations",
        type: "error"
        });
    }
    else{
      this.recSingleLoading = true;
      console.log(this.barcodeList);
      this.servi.sendBarcodesList(this.barcodeList).subscribe((data: any) => {
        Swal.fire({
          title: "Success",
          text: "Barcodes sent",
          type: "success"
          }).then( () => {
            this.recSingleLoading = false;
            console.log("success ?")
          });
          this.barcodeList = [];
          this.sendDisabled = true;
      }, error => {
        Swal.fire({
          title: "Error",
          text: "Try again later",
          type: "error"
          }).then(function() {
            this.recSingleLoading = false;
            console.log('service error');
          });
      });
    }

  }
}

