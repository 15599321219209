
  <app-navbar></app-navbar>
  <div id="content">
    <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
    <div id="container">
      <div class="contenedor container">
        <div class="row">
          <div class="col-12">
            <label>Buscar por Code:</label>
            <input type="text" [(ngModel)]="jicCode" class="form-control col-4" name="code" (change)="buscarEO()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
