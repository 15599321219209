import { Orders } from './../modelo/order';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EDocService {

   urlTally = environment.ENDPOINT + 'esignaturebff/order-works-tally-date-dtOrden';
   urlDate = environment.ENDPOINT + 'esignaturebff/order-works-date-dtOrden';
   urlRFI = environment.ENDPOINT + 'esignaturebff/order-rfi-tally-date-dtOrden';
   urlProcessBarcodes = environment.ENDPOINT + 'esignaturebff/process-barcodes';
   urlValidateBarcodeList = environment.ENDPOINT + 'esignaturebff/validate-barcodes';
   urlProcessBarcodeList = environment.ENDPOINT + 'esignaturebff/process-barcodes';

  constructor(private http: HttpClient) { }

  consultaDate(orden: Orders) {
    return this.http.post(this.urlDate, orden);
  }

  consultaTally(orden: Orders) {
    return this.http.post(this.urlTally, orden);
  }

  consultaRFI(orden: Orders) {
    return this.http.post(this.urlRFI, orden);
  }

  procesarBarcodes(barcodes : any[]) {
    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "barcodes": barcodes
    }
    return this.http.post(this.urlProcessBarcodes, data);
  }

  validateBarcodeList(barcodes: any[]){
    console.log(barcodes);

    var vals = barcodes.map(function(a) {return {barcode: a.barcode,type: a.type };});
    // console.log(vals);
    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "barcodes": barcodes
    }
    return this.http.post(this.urlValidateBarcodeList, data);
  }

  sendBarcodesList(barcodesList:any[]){

    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "barcodes": barcodesList
    }

    console.log(data);

    return this.http.post(this.urlProcessBarcodeList, data);

  }

}
