<app-navbar></app-navbar>
<div id="container">
    <div id="content" class="col-12">
        <div *ngIf="process.profile.procesing && process.profile.load; then thenBlock1 else elseBlock1"></div>
        <ng-template #thenBlock1>
            <ul class="nav nav-tabs tabs mt-3 col-11" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                        aria-controls="home" aria-selected="true" translate>{{'upload' |translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                        aria-controls="profile" aria-selected="false" translate>{{'procesar' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#images" role="tab"
                        aria-controls="profile" aria-selected="false" translate>{{'procesar_imagenes' | translate}}</a>
                </li>
                <li *ngIf="process.profile.selectPrincipal" class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#edoc" role="tab"
                        aria-controls="profile" aria-selected="false" translate>{{'eDoc' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#descomprimir" role="tab"
                        aria-controls="profile" aria-selected="false" translate>{{'descomprimir' | translate}}</a>
                </li>
                <li *ngIf="process.profile.selectPrincipal" class="nav-item">
                    <a class="nav-link" id="download-tab" data-toggle="tab" href="#download" role="tab"
                        aria-controls="profile" aria-selected="false" translate>{{'descargar' | translate}}</a>
                </li>
            </ul>
        </ng-template>
        <ng-template #elseBlock1>
            <div *ngIf="process.profile.procesing; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <ul class="nav nav-tabs tabs mt-3 col-11" id="myTab" role="tablist" *ngIf="process.profile.procesing">
                    <li class="nav-item">
                        <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                            aria-controls="profile" aria-selected="false" translate>{{'procesos_tabla' | translate}}</a>
                    </li>
                </ul>
            </ng-template>

            <ng-template #elseBlock>
                <ul class="nav nav-tabs tabs mt-3 col-11" id="myTab" role="tablist"
                    *ngIf="process.profile.load == false">
                    <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                            aria-controls="home" aria-selected="true" translate>{{'upload' |translate}}</a>
                    </li>
                </ul>

            </ng-template>
            <div *ngIf="process.profile.descomprimir == false; then descomprimirBlock"></div>
            <ng-template #descomprimirBlock>
                <ul class="nav nav-tabs tabs mt-3 col-11" id="myTab" role="tablist"
                    *ngIf="process.profile.load == false">
                    <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#descomprimir" role="tab"
                            aria-controls="home" aria-selected="true" translate>{{'descomprimir' | translate}}</a>
                    </li>
                </ul>

            </ng-template>
        </ng-template>

        <div class="tab-content pb-5" id="myTabContent">
            <div *ngIf="process.profile.procesing && process.profile.load; then thenBlock2"></div>
            <ng-template #thenBlock2>
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"
                    *ngIf="process.profile.load">
                    <div class="mt-5 animated fast ">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="card fondo">
                                    <form class="" action="" (ngSubmit)="submit(forma)" #forma="ngForm">
                                        <div class="card-header">
                                            <select [disabled]="!process.profile.selectPrincipal"
                                                *ngIf="listoConfirmado" name="flota"
                                                (change)=" completado = false; rutass = false; mensajes = false;"
                                                class="form-control w-50" [(ngModel)]="flota" required>
                                                <option value='' hidden>{{'seleccione_flota' | translate}} </option>
                                                <option value="A320">A320</option>
                                                <option value="B767">B767</option>
                                                <option value="B777">B777</option>
                                                <option value="B787">B787</option>
                                                <option value="A350">A350</option>
                                            </select>
                                        </div>
                                        <div class="m-3">
                                            <div *ngIf="listoConfirmado"
                                                class="flex form-group justify-content-between">
                                                <input disabled="disabled" type="text"
                                                    placeholder="{{'ruta_destino'| translate}}"
                                                    [(ngModel)]="carpetaDestino.dic" class="form-control tamano"
                                                    name="diccionario">

                                                <button *ngIf="process.profile.obtenerCarpetas"
                                                    [disabled]="flota.length === 0" class="btn btn-outline-primary"
                                                    (click)="getCarpetas(); mensaje = false; carpetaDestino.dic = ''; rutass = true; rutas1 = true; rutas2 = false; path1 = []; path2 = [];oculto = true; ocultoButom1 = false; ocultoButom2 = false; ocultarFlota= false">
                                                    <i class="fas fa-folder"></i> </button>
                                              
                                                
                                                
                                                <div *ngIf="process.profile.CrearCarpetas"
                                                    class="text-center procesar mb-2">
                                                    <button [disabled]="flota.length === 0" (click)="abrirModal()"
                                                        class="btn btn-primary">{{'crea_carpeta'| translate}}</button>
                                                </div>

                                            </div>



                                            <div class="form-group d-flex justify-content-between flex-wrap">
                                                <div *ngIf="process.profile.cargarArchivos" class="seleccion">
                                                    <input [disabled]="flota.length === 0" id="fichero" type="file"
                                                        (change)="onArchivoSeleccionado1($event); listoConfirmado = true; "
                                                        multiple>
                                                    <label *ngIf="cargando" for="fichero" class="btn btn-primary"><i
                                                            class="fas fa-file-alt mr-2"></i>
                                                        {{'selecciona_archivos' | translate}}</label>

                                                    <button type="button" *ngIf="botonCancelar" (click)="OnDestroy(); "
                                                        class="btn btn-danger carga">{{'cancelar'| translate}}</button>

                                                </div>
                                                <div *ngIf="process.profile.cargarArchivos" class="text-right">
                                                    <button *ngIf="listoConfirmado"
                                                        (click)="procesarArchivo(tecnicalFiles)"
                                                        class="btn btn-primary text-center carga font"> <i
                                                            class="fas fa-upload mr-2 text-white font"></i>{{'upload_files'| translate}}</button>




                                                    <button class="border-0" [disabled]="!terminoProceso"
                                                        *ngIf="!listoConfirmado"
                                                        (click)="listoConfirmado = true; tecnicalFiles = []; carpetaDestino.dic = ''; cargando = true;"><i
                                                            class="fas fa-check-square text-success fa-3x"></i> <br>
                                                        <p *ngIf="!terminoProceso" class="text-primary font">
                                                            {{'espere' | translate}}
                                                        </p>

                                                        <p *ngIf="terminoProceso"
                                                            (click)="rutass = false; fallo = false;"
                                                            class="text-primary font">{{'presione_salir' | translate}}
                                                        </p>
                                                    </button>

                                                </div>

                                            </div>


                                            <div *ngIf="tecnicalFiles.length !== 0" class="fondo scroll-">
                                                <table class="table text-center table-hover table-primary ">
                                                    <thead class="font">
                                                        <tr>

                                                            <th scope="col">{{'archivo' | translate}}</th>
                                                            <th scope="col" *ngIf="progres"> {{'progress' | translate}}
                                                            </th>

                                                            <th scope="col" *ngIf="carga"> {{'eliminar' | translate}}
                                                            </th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let archivo of tecnicalFiles; let i = index">
                                                            <td> {{archivo.tecnicalFile['name']}} <br>
                                                                <p class=" text-success font text-center"
                                                                    *ngIf="archivo.finalizado">
                                                                    {{'carga_exitosa' | translate}}</p>
                                                                <p class=" warning font text-center"
                                                                    *ngIf="archivo.error">
                                                                    {{'carga_en_proceso' | translate}}</p>
                                                                <p class="text-center text-primary font"
                                                                    *ngIf="archivo.acepto">{{'espere' | translate}}</p>

                                                                <p *ngIf="archivo.fallo" class="text-danger">
                                                                    {{'error_al_subir_archivo' | translate}}</p>
                                                            </td>
                                                            <td *ngIf="archivo.progress > 0"
                                                                class="progress-bar progress-bar-striped bg-success text-center mt-3"
                                                                role="progressbar" [style.width.%]="archivo.progress">
                                                                {{archivo.progress + '%'}}
                                                            </td>
                                                            <td *ngIf="carga" class="moverIcono mt-3">
                                                                <i (click)="eliminarArchivo(archivo.tecnicalFile['name'])"
                                                                    class="fas fa-trash-alt text-danger"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>


                                            <div *ngIf="process.profile.visitarProcesos">
                                                <div *ngIf="botonProceso" class="text-left">
                                                    <span *ngIf="!service.procesos" class="boton procesar">
                                                        <button class="btn btn-primary carga" type="button"
                                                            (click)="verProcesos()"><i
                                                                class="fas fa-eye mr-2"></i>{{ 'ver_procesos'| translate}}</button>
                                                    </span>
                                                    <span *ngIf="service.procesos" class="boton procesar">
                                                        <button class="btn btn-primary tamanoBoton" type="button"
                                                            (click)="OcultarProcesos()"><i
                                                                class="fas fa-eye-slash mr-2"></i>
                                                            {{'ocultar_procesos' | translate}}</button>
                                                    </span>
                                                </div>
                                            </div>
                                            <br>


                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div class="col-lg-6 col-sm-12">


                                <div *ngIf="rutass">
                                    <span *ngIf="botonRegresar1">
                                        <a
                                            (click)="getCarpetas(); mensaje = false; carpetaDestino.dic = ''; rutass = true; rutas1 = true; rutas2=false;path1 = []; path2 = [];
                                            oculto = true; ocultoButom1 = false; ocultoButom2 = false; ocultarFlota= false"><i
                                                class="fas fa-angle-double-down text-primary m-2"></i></a>

                                    </span>

                                    <span *ngFor="let pat of path1; let i = index">
                                        <span *ngIf="pat.endsWith('/')">
                                            <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat)"
                                                class="hover mouse"><a>{{pat | splitPipe}}</a><i
                                                    class="fas fa-folder ml-1 text-primary mr-2"></i></a>
                                        </span>
                                        <span *ngIf="!pat.endsWith('/')">
                                            <a *ngIf="rutas1" (click)="devolverRuta(i); mandarRuta(pat)"
                                                class="hover mouse"><a>{{pat | splitPipe}}</a><i
                                                    class="fas fa-file-alt ml-1 text-primary mr-2"></i></a>
                                        </span>
                                    </span>


                                    <div class="card fondo scroll-">
                                        <div *ngIf="louding" class="text-center text-primary m-5">
                                            <i class=" text-center fas fa-sync fa-spin fa-4x"></i>
                                            <p class=" text-center mt-3">{{'espere' | translate}}</p>

                                        </div>

                                        <div *ngIf="oculto">

                                            <div class="card-body mouse" *ngFor="let carpeta of carpetas">
                                                <div *ngIf="carpeta.endsWith('/')">
                                                    <a (click)="mandarRuta(carpeta); oculto = false; ocultarFlota = true"
                                                        class="card-title mouse"> <i
                                                            class="fas fa-folder text-primary mr-2"></i><a>{{carpeta}}</a>
                                                    </a>
                                                </div>

                                                <div *ngIf="!carpeta.endsWith('/')">
                                                    <a (click)="mandarRuta(carpeta)" class="card-title mouse"> <i
                                                            class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{carpeta}}</a></a>
                                                </div>


                                            </div>
                                        </div>

                                        <div *ngIf="ocultarFlota">

                                            <div class="card-body mouse" *ngFor="let ruta of rutas">
                                                <div *ngIf="ruta.endsWith('/')">
                                                    <a (click)="mandarRuta(ruta)" class="card-title mouse"><i
                                                            class="fas fa-folder m-2 text-primary mr-2"></i><a>{{ruta | splitPipe}}</a></a><br>
                                                </div>
                                                <div *ngIf="!ruta.endsWith('/')">
                                                    <a (click)="mandarRuta(ruta)" class="card-title mouse"> <i
                                                            class="fas fa-file-alt ml-1 text-primary mr-2"></i><a>{{ruta | splitPipe}}</a></a><br>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <span *ngIf="completado">
                                    <div class="text-right">
                                        <span (click)="rutass = false; completado = false; mensaje = false"><i
                                                class="fas fa-check-square text-success  fa-3x"></i></span>
                                    </div>
                                </span>

                            </div>



                        </div>


                        <div *ngIf="service.procesos">
                            <div class="row justify-content-center">
                                <div class="col-8">
                                    <div *ngIf="service.loudingProcesos" class="text-center">
                                        <img src="assets/resources/images/spinner.gif"><br>
                                        <span class="text-primary font">{{'espere' | translate}}</span>
                                    </div>
                                    <div class="card fondo">
                                        <div class="card-body scroll- p-4">
                                            <div *ngFor="let local of service.nuevaListaCargar">

                                                <span class="font">{{'fecha' | translate}} : </span> <span
                                                    class="text-primary">
                                                    {{local.date | date: 'medium'}}</span> <br>
                                                <span class="font">{{'flota' | translate}} :</span> {{local.flota}} <br>
                                                <span class="font">{{'ruta' | translate}} :</span> {{local.path}} <br>
                                                <span class="font"> {{'archivo' | translate}} :</span>
                                                {{local.nameFiles}} <br>
                                                <span class="font"> {{'usuario' | translate}} :</span> {{local.user}}

                                                <hr>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade show" id="profile" role="tabpanel" aria-labelledby="profile-tab"
                    *ngIf="process.profile.procesing">
                    <app-procesar></app-procesar>
                </div>
                <div class="tab-pane fade show" id="images" role="tabpanel" aria-labelledby="profile-tab"
                    *ngIf="process.profile.procesing">
                    <app-imagenes></app-imagenes>
                </div>
                <div class="tab-pane fade show" id="descomprimir" role="tabpanel" aria-labelledby="profile-tab"
                    *ngIf="process.profile.descomprimir">
                    <app-descomprimir></app-descomprimir>
                </div>
                <div class="tab-pane fade show" id="download" role="tabpanel" aria-labelledby="profile-tab">
                    <app-download></app-download>
                </div>

                <div class="tab-pane fade show" id="edoc" role="tabpanel" aria-labelledby="profile-tab">
                    <app-e-doc></app-e-doc>
                </div>
            </ng-template>

        </div>

    </div>

    <app-footer></app-footer>