import { Listado } from './../modelo/listado';
import { TecnicalFile } from './../modelo/tecnical-file.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  lista: Listado;
  procesosD = false;
  procesosP = false;
  procesos = false;
  mensajes = false;
  completado3 = false;
  loudingProcesos = false;
  loudingProcesosDerecha = false;
  realizoConsultaDescomprimir = false;
  realizoConsultaProcesar = false;
  realizoConsultaCargar = false;
  nuevaListaProcesar: any[] = [];
  nuevaListaDescomprimir: any[] = [];
  nuevaLista: any[] = [];
  nuevaListaCargar: any[] = [];
  nueva: any[] = [];
  localStorageCompleto: any[] = [];
  dia = new Date();
  hora: any;
  minuto: any;
  segundo: any;
  url = environment.ENDPOINT + 'esignaturebff/saveHistorial';
  urlListado = environment.ENDPOINT + 'esignaturebff/generateList';
  urlConsulta = '';

  constructor(private http: HttpClient) { }

  saveDocumentsPro(flota?: any, amm?: any, diccionario?: any, pathRuta?: any, version?: any) {
    const data = {
      'historialesProcesar':
        [{
          'date': new Date().toString(),
          'user': JSON.parse(sessionStorage.getItem('user'))._name,
          'flota': flota,
          'pathAMM': amm,
          'pathDiccionario': diccionario,
          'pathVersion': pathRuta,
          'version': version
        }],

      'tipoProceso': 'procesar'
    };

    if (flota === '' || amm === '' || diccionario === '' || pathRuta === '') {
      return;

    } else {
      if (version === "") {
        data.historialesProcesar[0].version = "version";
      }
      this.loudingProcesosDerecha = true;
      this.http.post(this.url, data).subscribe((dato: any) => {

        const sinVersion = dato.historialesProcesar.reverse();
        // console.log(sinVersion);
        for (let i = 0; i < sinVersion.length; i++) {

          if (sinVersion[i].version === 'version' || sinVersion[i].version === 'null' || sinVersion[i].version === 'amm') {
            sinVersion[i].version = '';
          }
          this.nuevaListaProcesar = sinVersion;
        }

        console.log(this.nuevaListaProcesar);
        this.loudingProcesosDerecha = false;
        this.mensajes = true;
        this.completado3 = true;

        return this.nuevaListaProcesar;
      });
    }
  }


  guardarStoragePro(datos: any) {
  }


  saveDocumentsDes(origen?: string, destino?: string) {

    const data = {
      'historialesDescomprimir':
        [{
          'date': new Date().toString(),
          'user': JSON.parse(sessionStorage.getItem('user'))._name,
          'pathSourceFiles': origen,
          'pathDestinationFiles': destino
        }],
      'historialesProcesar': null,

      'historialesUpload': null,

      'tipoProceso': 'descomprimir'
    };

    this.http.post(this.url, data).subscribe((dato: any) => {
      this.nuevaLista = dato.historialesDescomprimir.reverse();

    });

    return this.nuevaLista;
  }


  saveDocumentsUpload(flota?: string, ruta?: string, files?: any[]) {
    const data = {
      'historialesUpload':
        [{
          'date': new Date().toString(),
          'flota': flota,
          'nameFiles': files,
          'path': ruta,
          'user': JSON.parse(sessionStorage.getItem('user'))._name
        }],
      'tipoProceso': 'Upload'
    };

    this.http.post(this.url, data).subscribe((dato: any) => {
      this.nuevaListaCargar = dato.historialesUpload.reverse();
    });
    return this.nuevaListaCargar;
  }

  guardarStorageDes(datos: any) {
  }

  getStorageDes() {
  }

  consultarProcesos(proceso: string) {
    this.loudingProcesos = true;
    this.urlConsulta = environment.ENDPOINT + 'esignaturebff/verHistorial/' + proceso;
    return this.http.get(this.urlConsulta).subscribe((dato: any) => {
      this.loudingProcesos = false;

      if (proceso === 'descomprimir') {
        this.procesosD = true;
        this.nuevaLista = dato.historialesDescomprimir;
        if (this.nuevaLista.length !== 0) {
          this.realizoConsultaDescomprimir = true;
        } else {
          this.realizoConsultaDescomprimir = false;
        }
        return this.nuevaLista;
      }

      if (proceso === 'Upload') {
        this.procesos = true;
        this.nuevaListaCargar = dato.historialesUpload;
        if (this.nuevaListaCargar.length !== 0) {
          this.realizoConsultaCargar = true;
        } else {
          this.realizoConsultaCargar = false;
        }
        return this.nuevaListaCargar;
      }


      if (proceso === 'procesar') {
        this.procesosP = true;
        const sinVersion = dato.historialesProcesar;
        for (let i = 0; i < sinVersion.length; i++) {
          if (sinVersion[i].version === 'version' || sinVersion[i].version === 'null' || sinVersion[i].version === 'amm') {
            sinVersion[i].version = '';
          }
          this.nuevaListaProcesar = sinVersion;
        }
        if (this.nuevaListaProcesar.length !== 0) {
          this.realizoConsultaProcesar = true;
        } else {
          this.realizoConsultaProcesar = false;
        }
        return this.nuevaListaProcesar;
      }
    }, (err: HttpErrorResponse) => {
      console.log('aqui esta el error', err);
      this.procesosD = false;
      this.procesosP = false;
      this.procesos = false;
      this.loudingProcesos = false;

      return;
    });
  }

  generaListado(listado: Listado) {
    return this.http.post(this.urlListado, listado);

  }
}
