import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  public logs = [
    {
      'fecha': '2018-08-05 / 11:43:13',
      'tipo': 'Carga de Archivos',
      'flota': 'A320',
      'status': 'Exitosa',
      'detalle': "AMM+DIC",
      'usuario': "@Pablo Silva"
    },
    {
      'fecha': '2018-06-07 / 13:47:31',
      'tipo': 'Ejecucion',
      'flota': 'B767',
      'status': 'Exitosa',
      'detalle': "sin errores",
      'usuario': "@Pablo Silva"
    },
    {
      'fecha': '2018-06-13 / 06:55:38',
      'tipo': 'Ejecucion',
      'flota': 'B777',
      'status': 'Exitosa',
      'detalle': "sin errores",
      'usuario': "@Pablo Silva"
    }
  
  ]
  constructor() { }

  public addLog(log){
    this.logs.unshift(log);
  }

  public getLog(){
    return this.logs;
  }
}
