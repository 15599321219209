export const environment = {
  production: false,
  message: 'Desarrollo',
  api_key: 'AIzaSyBFkSzGX9BAyJYyDpmZOj-lqB2IJLFvr1w',
  client_id: '541332801297-1ml9v12tdefdf001vp430bf10vo1ko1i.apps.googleusercontent.com',
  ENDPOINT: 'https://esignature.dev.appslatam.com/',
  EmpointStorage: 'maintenancedomain-dev-due5-esignt-files',
  url_document_control: 'https://esignt-document.dev.appslatam.com/web/document/control?bucketName=maintenancedomain-dev-due5-esignt-files&folderAndFileName=',
  process: {
    profile: {
      'admin': false,
      'load': false,
      'procesing': false,
      'eo': false,
      'descomprimir': false,
      'selectPrincipal': false,
      'cargarArchivos': false,
      'visitarProcesos': false,
      'CrearCarpetas': false,
      'botonDescom': false,
      'obtenerCarpetas': false,
    }
  }
};

export const configProperties = {
  configPath: 'assets/config/app.config.json'
}
