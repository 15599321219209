import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {

  constructor(private http: HttpClient) { }

  saveDocuments(fleet:string, dic:string, mpd:string, dtd:string, path:string): Observable<any> {

    let data = {
      "flota": fleet,
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "amm": path,
      "dic": dic,
      "mpd": mpd,
      "dtd": dtd,
      "path": path      
    }

    console.log("REQUEST SEND.getDocuments."+environment.ENDPOINT+"esignaturebff/saveDocument", JSON.stringify(data));
    return this.http.post(environment.ENDPOINT+"esignaturebff/saveDocument", data);

  }
}
