import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceDecompressService } from 'src/app/servicios/service-decompress.service';
import { DecompressRequest } from 'src/app/modelo/DecompressRequest';
import { DecompressResponse } from 'src/app/modelo/DecompressResponse';
import Swal from 'sweetalert2';
import { RutaRequest } from 'src/app/modelo/RutaRequest';
import { RutaResponse } from 'src/app/modelo/RutaResponse';
import { LocalStorageService } from 'src/app/servicios/local-storage.service';
import { CarpetasService } from 'src/app/servicios/carpetas.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-descomprimir',
  templateUrl: './descomprimir.component.html',
  styleUrls: ['./descomprimir.component.css']
})

export class DescomprimirComponent implements OnInit {

  rutaDes: RutaRequest = {
    path: ''

  };

  public process = environment.process;

  public pathSourceFiles = '';
  public pathDestinationFiles = '';

  rutaRequest: RutaRequest = new RutaRequest();
  rutaResponse: RutaResponse = new RutaResponse();

  public descomp: DecompressRequest = {
    'user': JSON.parse(sessionStorage.getItem('user'))._mail,
    'pathSourceFiles': this.pathSourceFiles,
    'pathDestinationFiles': this.pathDestinationFiles

  };

  decompResponse: DecompressResponse;
  changeText = false;
  louding = false;
  path1 = [];
  path2 = [];
  splitted: any;
  rutas: any[] = [];
  rutass = false;
  rutas1 = false;
  rutas2 = false;
  carpeta1 = false;
  carpeta2 = false;
  oculto = true;
  ocultoCarpeta = true;
  ocultoButom = false;
  ocultoButom1 = false;
  ocultoButom2 = false;
  ocultarFlota = false;
  segundaCarpeta: any[] = [];
  carpetas: any[] = [];

  user = JSON.parse(sessionStorage.getItem('user'));
  botonProceso = true;
  localStorageCompleto: any[] = [];
  mensaje = false;
  dia = new Date();
  hora: any;
  minuto: any;
  segundo: any;

  meses = new Array('Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
    'Noviembre', 'Diciembre');
  diasSemana = new Array('Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado');
  completado = false;
  completado2 = false;
  botonRegresar1 = false;
  botonRegresar2 = false;
  ddata: string = "";

  constructor(public servi: CarpetasService, private service: ServiceDecompressService,
    public services: LocalStorageService, private translateService: TranslateService, private http: HttpClient) {
    Object.keys(this.process.profile).forEach(element => {
      if (this.user._process.profile[element] !== null && this.user._process.profile[element] !== undefined) {
        this.process.profile[element] = this.user._process.profile[element];
      }
    });
  }



  mueveReloj() {
    this.dia = new Date();
    this.hora = this.dia.getHours();
    this.minuto = this.dia.getMinutes();
    this.segundo = this.dia.getSeconds();
    this.hora = this.colocar0(this.hora);
    this.minuto = this.colocar0(this.minuto);
    this.segundo = this.colocar0(this.segundo);
    setTimeout('mueveReloj()', 1000);
  }

  colocar0(i: any) {

    if (i < 10) { i = '0' + i; } // Añadir el cero en números menores de 10
    return i;
  }

  ngOnInit() {
    this.localStorageCompleto = this.services.nuevaLista;
    if (this.localStorageCompleto[0] === undefined) {
    } else {
      this.botonProceso = true;
    }

    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ddata = res.ip;
    });
  }


  getCarpetas() {
    this.louding = true;
    this.carpetas = [];
    this.segundaCarpeta = [];
    this.rutaDes.path = '';
    this.completado2 = false;
    this.completado = false;
    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {

      this.louding = false;
      if (this.oculto) {
        this.carpetas = data.rutas;
        this.botonRegresar2 = false;
        this.botonRegresar1 = true;
        return this.carpetas;
        // los guarda en el segundo
      } else if (this.ocultoButom1) {
        this.segundaCarpeta = data.rutas;
        this.botonRegresar1 = false;
        this.botonRegresar2 = true;

        return this.segundaCarpeta;
      }

    }, (error) => {
      this.rutass = false;
      this.pathSourceFiles = '';
      this.louding = false;
     });
  }

  devolverRuta(i) {
    this.path1.splice(i);
    this.path2.splice(i);
  }


  mandarRuta(ruta: any) {
    this.completado2 = false;
    this.completado = true;
    if (this.rutas1) {
      if (!ruta.endsWith('/')) {
        Swal.fire({
          type: 'error',
          title: this.translateService.instant('solo_carpetas'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }

      this.louding = true;
      if (!this.path1.includes(ruta)) {
        this.path1.push(ruta);
        console.log('prueba', this.path1);
        this.oculto = false;
        this.ocultarFlota = true;
        this.botonRegresar1 = true;

      } else {
        Swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;

      }
    }


    if (this.rutas2) {
      if (!ruta.endsWith('/')) {
        Swal.fire({
          type: 'error',
          title: this.translateService.instant('solo_carpetas'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }

      this.rutas = [];

      if (!this.path2.includes(ruta)) {
        this.louding = true;
        this.ocultoButom1 = false;
        this.ocultoButom2 = true;
        this.path2.push(ruta);
        this.botonRegresar2 = true;
      } else {
        Swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;

      }
    }


    this.rutaDes.path = ruta;

    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {

      this.louding = false;

      this.rutas = data.rutas;

      if (this.ocultarFlota) {
        this.pathSourceFiles = ruta;

      } else if (this.ocultoButom2) {
        this.pathDestinationFiles = ruta;
      }

      return this.rutas;

    }, (error) => {
      this.rutass = false;
      this.louding = false;
      this.pathSourceFiles = '';
      this.pathDestinationFiles = '';
     });

  }
  title = 'descomprimirArchivo';

  descomprimir() {

    if (this.pathDestinationFiles === '' || this.pathSourceFiles === '') {
      Swal.fire({
        type: 'error',
        title: this.translateService.instant('origen_destino'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      return;

    } else {
      this.completado = false;
      this.completado2 = true;
      const text = this.translateService.instant('notificacion_correo') + this.descomp.user +
       this.translateService.instant('esta_descomprimido');
      Swal.fire({
        type: 'success',
        title: text,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.descomp.pathSourceFiles = this.pathSourceFiles;
        this.descomp.pathDestinationFiles = this.pathDestinationFiles;
        this.service.descomprimirService(this.descomp).subscribe(ob => {
          this.decompResponse = ob;
          this.pathDestinationFiles = '';
          this.pathSourceFiles = '';
          window.close();

        }, (error) => {
          this.rutass = false;
          this.louding = false;
          this.pathSourceFiles = '';
          this.pathDestinationFiles = '';
         });

      });
    }

  }

  guardarDatos() {
    if (this.pathSourceFiles === '' || this.pathDestinationFiles === '') {
      return;
    } else {
      this.services.saveDocumentsDes(
        this.pathSourceFiles,
        this.pathDestinationFiles
      );
      this.mensaje = true;
      this.localStorageCompleto = this.services.nuevaLista;
      this.botonProceso = true;
    }

  }

  verProcesos() {
    this.services.procesosD = true;
    const data = 'descomprimir';
    this.services.consultarProcesos(data);
  }

  OcultarProcesos() {
    this.services.procesosD = false;

  }
  abrirModal() {

    Swal.fire({
      title: this.translateService.instant('crea_carpeta'),
      input: 'text',
      inputValue: this.ddata,
      showCancelButton: true,
    }
    ).then((result) => {
      if (result.value) {
        if (!result.value.endsWith('/')) {
          result.value = result.value + '/';
        }
        this.pathDestinationFiles = this.pathDestinationFiles + result.value;
      }
    });
  }

}



