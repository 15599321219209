import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPipe'
})
export class SplitPipePipe implements PipeTransform {
  transform(value: any): any {
      let splitted;

      splitted = value.split('/');

      if ( value.endsWith('/')) {

        return splitted[splitted.length - 2];

      } else {

        return splitted[splitted.length - 1];

      }

    }


}
