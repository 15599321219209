import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/servicios/message.service';
import { Router } from '@angular/router';
import { EngineeringOrderService } from '../../servicios/engineeringOrder.service'

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.css']
})
export class BuscarComponent implements OnInit {

  public loading = false;
  public jicCode: string;

  constructor(private router: Router, private eoService: EngineeringOrderService) { }

  ngOnInit() {
  }

  buscarEO() {
    this.loading = true;
    this.router.navigate(['engineering_order', this.jicCode]);
  }

}
