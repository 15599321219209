import { TecnicalFile } from './../modelo/tecnical-file.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RutaRequest } from '../modelo/RutaRequest';
import { RutaResponse } from '../modelo/RutaResponse';
import { FindFlotaRequest } from '../modelo/find-flota-request';
import { environment } from 'src/environments/environment';
import { Observable ,  from } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarpetasService {

  public dic = '';
  tecnicalFiles: TecnicalFile[] = [];
  tecnicalFiles2: TecnicalFile[] = [];
  fileToUpload: any;
  httpOptionsAccessCors: any;
  // public url_file = 'http://35.231.194.3:9012/uploadFile';
  public url_file = environment.ENDPOINT + 'esignaturebff/uploadFile';

  private Url = environment.ENDPOINT + 'esignaturebff/esignatureListPath';
  private url = environment.ENDPOINT + 'esignaturebff/getPathDeploy';

  constructor(private http: HttpClient) {
    this.httpOptionsAccessCors = {
      headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    };
    }

  postCarpetas(ruta: RutaRequest) {
    return this.http.post<RutaResponse>(this.Url, ruta);
  }


  postVersiones(flota: FindFlotaRequest) {
    return this.http.post<RutaResponse>(this.url, flota);
    }


    SubirArchivo(files?: FormData[]): Observable<any> {
      return from(files).pipe( concatMap(file => <Observable<any>> this.http.post(this.url_file, file,
           { reportProgress: true, observe: 'events' }))
      );
    }
}
