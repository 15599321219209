<footer class="footer bg-latam br-red">
	<div class="d-flex">
		<div class="col-10 d-flex">
			<div class="col-1 flags">
				<span class="flag-icon flag-icon-gb pointer" data-container="body" data-toggle="popover" data-placement="top" data-content="Select Language English" (click)="switchLanguage('en')"></span>
			</div>
			<div class="col-1 flags">
				<span class="flag-icon flag-icon-pt pointer" data-container="body" data-toggle="popover" data-placement="top" data-content="Selecione o idioma Português" (click)="switchLanguage('pt')"></span>
			</div>
			<div class="col-1 flags">
				<span class="flag-icon flag-icon-es pointer" style="line-height:1.3em" data-container="body" data-toggle="popover" data-placement="top" data-content="Seleccionar Lenguaje Español" (click)="switchLanguage('es')"></span>
			</div>
		</div>
		<img class="col-3 float-right " style="max-width: 9%;" src="assets/resources/images/ltm-logo.svg">
	</div>
</footer>