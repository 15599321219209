import { Component, OnInit, ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../servicios/message.service';
import { EngineeringOrderService } from '../../servicios/engineeringOrder.service';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';


// @Pipe({
//   name: 'sanitizeHtml'
// })
@Component({
  selector: 'app-eo',
  templateUrl: './eo.component.html',
  styleUrls: ['./eo.component.css']
})
export class EoComponent implements OnInit {

  private hasNumber = new RegExp(/0-9/);
  private usuario = JSON.parse(sessionStorage.getItem('user'));
  public loading = false;
  public loquequieras=[]
  public loquequieras1=[];
  public flotaLS = "B787INSP;B777INSP;B767INSP;A320INSP;A350INSP";
  public errorCode = false;
  public success = false;
  public error = false;
  public fleet;
  public assembly;
  public jicCode;
  public previewButton = false;
  private jobCardUtil;
  public editorUrl = "https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js"
  public ckconfig = { versionCheck: false };
  public jobCard ={
    "name": "",
    "documentReference": "",
    "instructions": "",
    "description": "",
    "assemblyId":"",
    "zoneCodes": [
      ""
    ],
    "panelCodes": [
      ""
    ],
    "jobCardSteps": [
      {
        "description": "",
        "order": 0,
        "applicabilityRange": "",
        "certifyingSkills": [
        ]
      }
    ]
  };

  constructor(private translate:TranslateService, private route: ActivatedRoute,private mensajeria:MessageService
              , private eoService: EngineeringOrderService,private ref: ChangeDetectorRef,private _sanitizer:DomSanitizer) {
    translate.setDefaultLang(this.usuario['_locale']);

  }

  ngOnInit() {
      if(undefined!==this.route.snapshot.params['id']){
        this.cargarJson();
      }
  }
  cargarJson(){
    this.checkCode();
  }

  enviar(){
    this.mensajeria.loadingTransparent();
    setTimeout(()=>{
      this.mensajeria.exito("Enviado Exitosamente");
      $("button.btn.btn-danger").trigger("click");
    },1200);

  }

  addStep(){
    if(this.fleet!=undefined){
      this.jobCard.jobCardSteps.push(
        {
          "description": "",
          "order":0,
          "applicabilityRange": "",
          "certifyingSkills": [
            {
                "laborSkill": this.fleet,
                "requireInspection": false
            }
          ]
        }
      );
    }
  }

  onFileChange($event, reference){
    alert("check component");
    // const reader = new FileReader();
    // let data = $event.target["files"][0];
    // console.log(data);
    // reader.onload = e => reference.url = reader.result;
    // reader.readAsDataURL(data);
  }

  removeStep(indice){
    this.jobCard.jobCardSteps.splice(indice, 1);
  }

  checkCode(){
    if (this.jicCode!=undefined && this.jicCode.length > 0 && this.fleet!=undefined && this.assembly!=undefined){
      this.loading = true;
      this.eoService.jobCardDefinition(this.jicCode,this.fleet,this.assembly).subscribe(
        response => {
          if(response.jobCardSteps!=undefined){
            if(response.jobCardSteps.length >= 1){
              this._sanitizer.bypassSecurityTrustHtml(response.documentReference);
              this._sanitizer.bypassSecurityTrustHtml(response.instructions);
              this._sanitizer.bypassSecurityTrustHtml(response.description);
              this._sanitizer.bypassSecurityTrustHtml(response.assemblyId);
              response.jobCardSteps.forEach((item,index)=>{
                this._sanitizer.bypassSecurityTrustHtml(item.description);
                item.certifyingSkills.forEach((d,i)=>{
                  this.loquequieras[index]=d.requireInspection=="true"?true:false;
                  if(d.laborSkill.toUpperCase().includes("INSP")){
                    if(this.flotaLS.includes(d.laborSkill.toUpperCase())){
                      this.loquequieras1[index]=d.laborSkill.toUpperCase().includes("INSP")?true:false;
                    }
                  }else{
                    if (d.laborSkill.includes("B787")){
                      this.fleet="B787";
                    }else if (d.laborSkill.includes("B777")){
                      this.fleet="B777";
                    }else if (d.laborSkill.includes("B767")){
                      this.fleet="B767";
                    }else if (d.laborSkill.includes("A320")){
                      this.fleet="A320";
                    }else if (d.laborSkill.includes("A350")){
                      this.fleet="A350";
                    }else{
                      this.fleet=d.laborSkill;
                    }
                  }
                });
                if(item.certifyingSkills.length==0){
                  this.loquequieras[index]=false;
                  this.loquequieras1[index]=false;
                }
                this.jobCard=response;
                this.loading = false;
                this.errorCode = false;
                this.previewButton=true;
              });
            }
              this.jobCard=response;
              this.loading = false;
              this.errorCode = false;
              this.previewButton=true;
            }else{
              this.jobCard=response;
              this.loading = false;
              this.errorCode = false;
              this.previewButton=true;
              this.mensajeria.error("No Existe Informacion de la JIC y Assembly");
            }
        },
        error => {
          this.previewButton=false;
          this.loading = false;
          this.errorCode = true;
          this.fleet = null;
          this.jobCard={
            "name": "",
            "documentReference": "",
            "instructions": "",
            "description": "",
            "assemblyId":"",
            "zoneCodes": [
              ""
            ],
            "panelCodes": [
              ""
            ],
            "jobCardSteps": [
              {
                "description": "",
                "order": 0,
                "applicabilityRange": "",
                "certifyingSkills": [
                ]
              }
            ]
          };
        }
      )
    }
  }

  submit(){
    if(this.fleet!=undefined){
      if(this.jobCard.jobCardSteps!=undefined && this.jobCard.jobCardSteps.length>0){
        let element = document.getElementById("closeModal");
        element.click();
        this.loading = true;
        this.success = false;
        this.error = false;
        this.jobCard.jobCardSteps.forEach((item,index)=>{
          if(this.loquequieras[index]==undefined){
            this.loquequieras[index]=false;
          }
          if(this.loquequieras1[index]==undefined){
            this.loquequieras1[index]=false;
          }
          item.certifyingSkills.forEach((data,i)=>{
            let flotaINSP=this.fleet+"INSP";
            if(this.loquequieras[index]==false){
              data.requireInspection=false;
              if(this.loquequieras1[index]==false){
                if(data.laborSkill.includes(flotaINSP)){
                  item.certifyingSkills.splice(i,1);
                }
              }else{
                if(data.laborSkill.includes(flotaINSP)==false
                && item.certifyingSkills.length == (i+1)){
                  item.certifyingSkills.unshift({
                    laborSkill:flotaINSP,
                    requireInspection:false
                  });
                }
              }
            }else{
              if(this.loquequieras1[index]==false){
                if(data.laborSkill.includes(flotaINSP)){
                  item.certifyingSkills.splice(i,1);
                }else{
                  data.requireInspection=true;
                }
              }else{
                if(data.laborSkill.includes(flotaINSP)==false
                && item.certifyingSkills.length ==(i+1)){
                  item.certifyingSkills.unshift({
                    laborSkill:flotaINSP,
                    requireInspection:true
                  });
                }
                data.requireInspection=true;
              }

            }
          });
          if(item.certifyingSkills.length==0){
            if(this.loquequieras[index]==false){
              if(item.certifyingSkills.length==0){
                item.certifyingSkills.push({
                "laborSkill": this.fleet,
                "requireInspection": false
               });
              }
              if(this.loquequieras1[index]==true){
                if(item.certifyingSkills.length==0){
                  item.certifyingSkills.push( {
                    "laborSkill": this.fleet+"INSP",
                    "requireInspection": false
                  });
                }
              }
            }else{
              if(item.certifyingSkills.length==0){
                item.certifyingSkills.push( {
                  "laborSkill": this.fleet,
                  "requireInspection": true
                });
              }
              if(this.loquequieras1[index]==true){
                if(item.certifyingSkills.length==0){
                  item.certifyingSkills.push( {
                    "laborSkill": this.fleet+"INSP",
                    "requireInspection": true
                  });
                }
              }
            }
          }
        });
        this.jobCard.jobCardSteps.forEach((d,indice)=>{
          let contador = 0;
          let flotaINSP=this.fleet+"INSP";
          d.certifyingSkills.forEach((skill,indi)=>{
            if(skill.laborSkill.includes(flotaINSP)){
              contador++;
            }
            if(contador>1){
              d.certifyingSkills.splice((indi),1);
            }
          })
        });
        this.eoService.sendOrder(this.jobCard,this.jicCode).subscribe(
          response => {
            this.borrarElementos();
            this.loading = false;
            this.success = true;
          },
          error => {
            console.log("submit - this.jobCard.."+JSON.stringify(this.jobCard));
            this.borrarElementos();
            this.loading = false;
            this.error = true;
          }
        );
      }else{
        this.mensajeria.error("Debe agregar tarea");
      }
    }else{
      this.mensajeria.error("Seleccione Flota");
    }
    this.mensajeria.mensajeCustom(["Se ha enviado a procesar..",this.jicCode.toString()]);
  }

  borrarElementos():void{
    this.jicCode="";
    this.fleet="";
    this.jobCard={
      "name": "",
      "documentReference": "",
      "instructions": "",
      "description": "",
      "assemblyId":"",
      "zoneCodes": [
        ""
      ],
      "panelCodes": [
        ""
      ],
      "jobCardSteps": [
        {
          "description": "",
          "order": 0,
          "applicabilityRange": "",
          "certifyingSkills": [
          ]
        }
      ]
    };;
  }
}

