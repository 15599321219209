import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagenesService {

  constructor(private http: HttpClient) { }
  
  enviarProcesar(flota:string, amm:string, dic:string, pathVersion:string, version:string, tags?:string): Observable<any> {

    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "flota":flota,
      "pathFilesAMM": amm,
      "pathFileDIC": dic,
      "pathVersion": pathVersion,
      "version": version,
      "toFront": true
    }

    if (flota == "B787"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturegenerateb787/generate",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturegenerateb787/generate", data); 
    }else if (flota == "B777"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturegenerateb777/generate",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturegenerateb777/generate", data); 
    }else if (flota == "B767"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"esignaturegenerateb767/generate",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"esignaturegenerateb767/generate", data); 
    }else if (flota == "A320"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"/esignaturegeneratea320/generate",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"/esignaturegeneratea320/generate", data);  
    }else if (flota == "A350"){
      console.log("REQUEST SEND.enviarProcesar."+environment.ENDPOINT+"/esignaturegeneratea350/pushToEsignatureMessages",JSON.stringify(data));
      return this.http.post(environment.ENDPOINT+"/esignaturegeneratea350/pushToEsignatureMessages", data); 
    }
  }
}
