<div class="mt-5" style="background-color: #E4E4E4;">

  <div class="row justify-content-center">
    <div class="col-lg-6 col-sm-12">
      <div class="card w-100 fondo">
        <div class="card-header text-center">
          <h5>{{'Record_Keeping'| translate}} <i class="fas fa-folder ml-5 text-primary "></i></h5>
        </div>
        <div class="card-body">
          <form action="">
            <div class="form-group  d-flex justify-content-center flex-wrap">
              <label class="mr-5 mt-2 font" for="start ">Task Card </label>
              <input class="btn btn-primary" [(ngModel)]="Works_date" type="date" id="start" name="trip-start">
              <button [disabled]="!butonConsulta" class="btn btn-outline-primary ml-2"
                (click)="consultaWorkDate()">{{'procesar' | translate}}</button>
            </div>

            <div class="form-group d-flex justify-content-center flex-wrap">
              <label class="mr-5 ml-4 mt-2 font" for="start1 "> Tally </label>
              <input class="btn btn-primary ml-3" [(ngModel)]="Works_tally" type="date" id="start1" name="trip-start1">
              <button [disabled]="!butonConsulta" class="btn btn-outline-primary ml-2"
                (click)="consultaWorkTally()">{{'procesar' | translate}}</button>
            </div>

            <div class="form-group d-flex justify-content-center flex-wrap">
              <label class="mr-5 ml-4 mt-2 font" for="start2 "> RFI </label>
              <input class="btn btn-primary ml-3" [(ngModel)]="Works_RFI" type="date" id="start2" name="trip-start2">
              <button [disabled]="!butonConsulta" class="btn btn-outline-primary ml-2"
                (click)="consultaRFIDate()">{{'procesar' | translate}}</button>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="card w-100 fondo">
        <div class="card-header text-center">
          <h5>{{'Record_Single'| translate }}. <i class="fas fa-folder ml-5 text-primary "></i></h5>
        </div>
        <div *ngIf="!recSingleLoading" class="card-body" style="margin: 0 auto;">

          <mat-form-field style="margin-left: 2rem;">
            <mat-label>{{'Type'| translate }}</mat-label>
            <mat-select [(ngModel)]="typeSelected">
              <mat-option *ngFor="let barcodeType of barcodeTypes" [value]="barcodeType.value">
                {{barcodeType.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <mat-form-field style="margin-left: 2rem;" class="example-form-field">
            <mat-label>{{'BarcodeEdoc'| translate }}</mat-label>
            <input matInput maxlength="8" type="text" [(ngModel)]="newBarcode">
            <button *ngIf="newBarcode" matSuffix mat-icon-button aria-label="Clear" (click)="newBarcode=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <button style="margin-left: 2rem;"mat-button class="add-btn" (click)="addBarcodeToList()">{{ 'Add' | translate}}</button>

          <div class="">
            <div class="records-titles">
              <div class="row-column-1">{{'Type'| translate }}</div>
              <div class="row-column-2">{{'BarcodeEdoc'| translate }}</div>
              <div class="row-column-3">{{'Validation'| translate }}</div>
              <div class="row-column-4">{{'Ignore_Validation'| translate }}</div>
              <div class="row-column-5">-</div>
            </div>
            <div class="records-rows">
              
              <!-- <div class="records-single-row">
                <div class="row-column-1">Taskcard</div>
                <div class="row-column-2">BARCODE#123</div>
                <div class="row-column-3"><mat-icon style="color: green;" aria-hidden="false" aria-label="Example home icon" fontIcon="check_circle"></mat-icon>
                </div>
                <div class="row-column-4"><mat-checkbox class="example-margin"></mat-checkbox></div>
                <div class="row-column-5"><button style="background-color: red; color: white;" mat-button>Remove</button></div>
              </div> -->
              <div *ngFor="let item of barcodeList; index as i" class="records-single-row">
                <div class="row-column-1">{{item.barcode}}</div>
                <div class="row-column-2">{{item.type}}</div>
                <div class="row-column-3" style="margin-left: 2rem;">
                  <mat-icon matTooltip="Validation OK" *ngIf="item.statusCode == 1" style="color: green;" aria-hidden="false" aria-label="Example home icon" fontIcon="check_circle"></mat-icon>
                  <mat-icon matTooltip="Incorrect validations" *ngIf="item.statusCode == 5" style="color: red;" aria-hidden="false" aria-label="Example home icon" fontIcon="cancel"></mat-icon>
                  <mat-icon matTooltip="Barcode error or Type not valid" *ngIf="item.statusCode == 2 || item.statusCode == 3 || item.statusCode == 4" style="color: black;" aria-hidden="false" aria-label="Example home icon" fontIcon="broken_image"></mat-icon></div>
                <div class="row-column-4"><mat-checkbox *ngIf="item.statusCode != 1 && (item.ignoreValidation == false || item.ignoreValidation == true)" checked="{{item.ignoreValidation}}" (change)="item.ignoreValidation = !item.ignoreValidation" class="example-margin"></mat-checkbox></div>
                <div class="row-column-5"><button style="background-color: #DC3545; color: white;" (click)="removeBarcodeFromList(i)" mat-button>{{ 'Remove' | translate }}</button></div>
              </div>
            </div>
          </div>

          <div style="margin-top: 1rem;">
            <button style="margin-left: 7rem;" mat-button class="add-btn" (click)="validateBarcodeList()">{{ 'Validate' | translate }}</button>
            <span style="margin-left: 4rem;" > {{this.barcodeList.length}} of 20 Barcodes</span>
            <button style="margin-left: 4rem; background-color: gray;" [disabled]="sendDisabled" *ngIf="sendDisabled" mat-button (click)="sendBarcodesList()" class="add-btn">{{ 'Send' | translate}}</button>
            <button style="margin-left: 4rem;" [disabled]="sendDisabled" *ngIf="!sendDisabled" mat-button (click)="sendBarcodesList()" class="add-btn">{{ 'Send' | translate}}</button>
          </div>
        <!-- <div class="" style="text-align: center;">
          <label class="mr-2 mt-2 bcode-input" style="font-weight: bold; display: inline-block;" for="start">{{ 'Bar_code' | translate }}</label>
          <input [(ngModel)]="barInput" type="text" name="barcode" class="form-control col-8 input-bcode" maxlength="8" id="" aria-describedby=""
          placeholder="{{ 'Insert_barcode' | translate }}">
          <button class="btn btn-outline-primary ml-2" [disabled]="!barInput.length" (click)="addBarcode()">{{ 'Add_barcode' | translate }}</button>
        </div>
        <br>
        <ul class="list-group">
          <li *ngFor="let data of barcodeRows; let i = index" class="list-group-item align-middle">
            <span class="name">{{ data }}</span>
            <button class="btn btn-danger float-right" (click)="delBarcode(i)">{{ 'Delete_barcode' | translate }}</button>
          </li>
        </ul>
        <p *ngIf="barcodeRows.length > 0" class="barcode-counter">Count: {{ barcodeRows.length }}</p>
        <button class="btn btn-outline-primary" [disabled]="!barcodeRows.length" (click)="sendBarcodes()">{{ 'Send_barcodes' | translate }}</button> -->
        </div>
        <mat-spinner style="margin:0 auto; min-height: 23rem;" *ngIf="recSingleLoading"></mat-spinner>
      </div>
    </div>
    <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '6px' }"></ngx-loading> -->
  </div>
</div>
