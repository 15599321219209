import { Router } from '@angular/router';
import { ErrorServiceService } from './../servicios/error-service.service';
import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import {catchError} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class ErroresService implements HttpInterceptor {

  constructor( private errorService: ErrorServiceService ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(error => {
        console.log('este es el error que se muestra', error);
        let errorMessage = '';

        if ( error.status === 502) {
          return throwError(errorMessage);
        }
        if (error instanceof ErrorEvent) {
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }
        this.errorService.show(errorMessage);
          return throwError(errorMessage);
      })
    );
  }
}
