<nav class="navbar navbar-expand-sm navbar-dark bg-latam">
  <img class="logo-hd" src="assets/resources/images/ltm-logo.svg">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse pl-5" id="navbarsExample03">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active" *ngIf="process.profile.procesing || process.profile.load">
        <a class="nav-link" href="home">
          <i class="material-icons text-white icon-header">home</i>
        </a>           	
      </li>
      <li class="nav-item dropdown" id="lidropdown1"  data-container="body" data-toggle="popover" data-placement="left" attr.data-content="{{ 'admin_perfiles' | translate }}" *ngIf="process.profile.admin">
          <a class="nav-link dropdown-toggle" href="" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons text-white icon-header">supervisor_account</i>
          </a>
          <div class="dropdown-menu bg-latam2" aria-labelledby="dropdown1">
            <a class="dropdown-item text-white" href="profile" id="liperfil" data-container="body" data-toggle="popover" data-placement="top" attr.data-content="{{ 'gestionar_pefiles' | translate }}">{{'perfil'|translate}}</a>
          </div>
      </li>
      <li  class="nav-item dropdown" id="lidropdown2"  data-container="body" data-toggle="popover" data-placement="left" attr.data-content="{{ 'eo' | translate }}" *ngIf="process.profile.eo">
        <a class="nav-link dropdown-toggle" href="" id="dropdown2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i  class="material-icons text-white icon-header">style</i>
        </a>
        <div  class="dropdown-menu bg-latam2" aria-labelledby="dropdown1">
          <a class="dropdown-item text-white" href="engineering_order" id="ordenIngeneria" data-container="body" data-toggle="popover" data-placement="top" attr.data-content="{{ 'eo' | translate }}">{{'crear'|translate}}</a>
          <!--a class="dropdown-item text-white" href="search" id="ordenIngeneria" data-container="body" data-toggle="popover" data-placement="top" attr.data-content="{{ 'eo' | translate }}">{{'buscar'|translate}}</a-->
        </div>
    </li>
    </ul>
      <div class="user-logout">
        <label class="text-white mr-3" style="margin-top: 0.5%" >{{user._name}}</label>
        <i class="material-icons text-white icon-header" style="cursor:pointer;"  (click)="logoff()">power_settings_new</i>
      </div>
  </div>
</nav>

